$baseFontSize: 12px;
/*--------------------- FONT --------------------------------*/
$fontHeading: 'Roboto Condensed', sans-serif;
$fontHeading_2: 'Oswald', sans-serif;
$fontText: 'Open Sans', sans-serif;
$fontIcon: 'FontAweSome';

/*COLOR LOADING*/
$colorLoading_1: #4bac52;
$colorLoading_2: #ef498b;
$colorLoading_3: #ffc107;

/*---------------------------------COLOR SET 2---------------------------------*/
/*--------------------- COLOR TEXT -------------------------*/
$colorText: #8b8b8b;
$colorText_2: #ffffff;
$colorText_3: #ffc600; 
$colorText_4: #252a2f;
/*--------------------- BUTTON COLOR -----------------------*/
$colorBtn_1: #ffc600;
$colorBtn_2: #252a2f;
/*--------------------- MAIN COLOR -------------------------*/
$mainColor_2: #252a2f;
$mainColor_1: #ffc600;
/*--------------------- COLOR LABEL ------------------------*/
$colorNewLabel: #ffc600; 
$colorSaleLabel: #ff0000; 
$colorViewLabel: #ff9000;
/*--------------------- COLOR BANNER LIMIT TIME OFFER-------*/
$colorLimit: #ffc600;

/*---------------------------------COLOR SET 3---------------------------------*/
/*--------------------- COLOR TEXT -------------------------*/
$colorText: #8b8b8b;
$colorText_2: #ffffff;
$colorText_3: #3498db; 
$colorText_4: #252a2f;
/*--------------------- BUTTON COLOR -----------------------*/
$colorBtn_1: #3498db;
$colorBtn_2: #252a2f;
/*--------------------- MAIN COLOR -------------------------*/
$mainColor_2: #252a2f;
$mainColor_1: #3498db;
/*--------------------- COLOR LABEL ------------------------*/
$colorNewLabel: #3498db; 
$colorSaleLabel: #ff0000; 
$colorViewLabel: #1a7eb8;
/*--------------------- COLOR BANNER LIMIT TIME OFFER-------*/
$colorLimit: #1a7eb8;

/*---------------------------------COLOR SET 4---------------------------------*/
/*--------------------- COLOR TEXT -------------------------*/
$colorText: #8b8b8b;
$colorText_2: #ffffff;
$colorText_3: #ed7743; 
$colorText_4: #252a2f;
/*--------------------- BUTTON COLOR -----------------------*/
$colorBtn_1: #ed7743;
$colorBtn_2: #252a2f;
/*--------------------- MAIN COLOR -------------------------*/
$mainColor_2: #252a2f;
$mainColor_1: #ed7743;
/*--------------------- COLOR LABEL ------------------------*/
$colorNewLabel: #ed7743; 
$colorSaleLabel: #ff0000; 
$colorViewLabel: #ffc600;
/*--------------------- COLOR BANNER LIMIT TIME OFFER-------*/
$colorLimit: #ed7743;

/*---------------------------------COLOR SET 1---------------------------------*/
/*--------------------- COLOR TEXT -------------------------*/
$colorText: #8b8b8b;
$colorText_2: #ffffff;
$colorText_3: #4bac52; 
$colorText_4: #252a2f;
/*--------------------- BUTTON COLOR -----------------------*/
$colorBtn_1: #4bac52;
$colorBtn_2: #252a2f;
/*--------------------- MAIN COLOR -------------------------*/
$mainColor_2: #252a2f;
$mainColor_1: #4bac52;
/*--------------------- COLOR LABEL ------------------------*/
$colorNewLabel: #4bac52; 
$colorSaleLabel: #ff0000; 
$colorViewLabel: #66afe9;
/*--------------------- COLOR BANNER LIMIT TIME OFFER-------*/
$colorLimit: #4bac52;
