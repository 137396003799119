#slider-wrapper {
  position: relative;
  /*Slideshow navigation*/
  .ls-nav-prev, 
  .ls-nav-next {
    position: absolute;
    top: 50%;
    z-index: 2;
    transform: translate(0,-50%);
    &:hover {
      &:before {
       color: $colorText_3;
      }
    }
  }
  .ls-nav-prev {
    left: 20px;
    &:before {
      content: "\f053";
      display: block;
      font-family: $fontIcon;
      font-size: 37px;
      color: #fff;
      transition: all 0.35s;
    }
    @media (max-width: 767px) {
      left: 5px;
      &:before {
        font-size: 25px;
      }      
    }
  }
  .ls-nav-next {
    right: 20px;
    &:before {
      content: "\f054";
      display: block;
      font-family: $fontIcon;
      font-size: 37px;
      color: #fff;
      transition: all 0.35s;
    }
    @media (max-width: 767px) {
      right: 5px;
      &:before {
        font-size: 25px;
      }      
    }
  }
  .ls-lt-container {
    transform: none !important;
  }
  .ls-slide {
    >.ls-bg {
      @media(max-width: 767px) {
        top: 0 !important;
      }
    }
  }
  .ls-video-layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100% !important;
    height: 100% !important;
    iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100% !important;
      height: 100% !important; 
    }
  }
}
/*Fullwidth slider*/ 
#full-slider-wrapper {
  #layerslider {
    .ls-slide {
      .ls-l.very_big_white {
        font-family: $fontHeading;
        color: #fff;
        font-weight: bold;
        font-size: 48px;
        font-size: 2.5vw;
        letter-spacing: 1px; 
        line-height: 1.2;
      }
      .ls-l.small_text {
        color: #fff;
        font-size: 16px;
        font-size: 0.8vw;
        font-style: italic;  
        font-family: $fontText; 
        text-transform: none;
        line-height: 1.2;
        @media (max-width: 767px) {
          display: none !important; 
        }
      }
      .ls-l.medium_text {
        font-weight: bold; 
        border-radius: 3px;
        font-size: 16px;
        font-size: 0.8vw;
        color: #fff;
        font-family: $fontText; 
        text-transform: uppercase;
        padding: 0.67vw 1.56vw;
        background-color: $mainColor_1;
        line-height: 1;
        span {
          font-size: inherit!important;
          line-height: 1;
          display: block;
        }
        @media (max-width: 991px) {
          padding: 10px 10px !important;
          font-size: 10px !important;
        }
      }
    }  
  }  
}
/*Not fullwidth slider*/
#slider-wrapper {
  overflow: hidden;
  padding-right: 0;
  padding-left: 0;
  padding-top: 0;
  @media (max-width: 767px) {
    padding-left: 0;
  }
  .ls-slide {
    font-size: 862px;
    @media (max-width: 1229px) {
      font-size: 690px;
    }
    @media (max-width: 991px) {
      font-size: 525px;
    }
    @media (max-width: 767px) {
      font-size: 90vw;
    }
    .ls-l.big_black {
      &:before,
      &:after {
        display: none;
      } 
      text-transform: capitalize;
      letter-spacing: 0; 
     color: $colorText_3;
      font-size: em(30px) !important;
      font-size: 3.48% !important;
      @media (max-width: 991px) and (min-width: 768px) {
        margin-top: -50px !important;
      }
    }
    .ls-l.very_big_white {
      font-family: $fontHeading;
      color: #fff;
      font-weight: bold;
      font-size: 55px;
      font-size: 6.38% !important;
      letter-spacing: 1px; 
      line-height: 1.2 !important;
      @media (max-width: 991px) and (min-width: 768px) {
        margin-top: -50px !important;
      }
    }
    .ls-l.small_text {
      color: #fff;
      font-size: 16px;
      font-size: 1.85%;
      font-style: italic;  
      font-family: $fontText; 
      text-transform: none;
      line-height: 1.2;
      @media (max-width: 991px) and (min-width: 768px) {
        margin-top: -50px !important;
      }
      @media (max-width: 767px) {
        display: none !important; 
      }
    }
    .ls-l.medium_text {
      font-weight: bold; 
      border-radius: 3px;
      font-size: 14px;
      font-size: 1.62%;
      color: #fff;
      font-family: $fontText; 
      text-transform: uppercase;
      padding: em(17px) em(25px);
      background-color: $mainColor_1;
      line-height: 1;
      span {
        font-size: inherit!important;
        line-height: 1;
        display: block;
      }
      @media (max-width: 991px) {
        padding: 10px 10px !important;
        font-size: 10px !important;
      }
      @media (max-width: 991px) and (min-width: 768px) {
        margin-top: -50px !important;
      }
    }
  } 
  #layerslider {
    width: 100% !important;
  }
  /*Set height for no full width slideshow on responsive*/
  #layerslider, .ls-inner,
  .ls-lt-container, .ls-slide {
    @media (max-width: 991px) and (min-width: 768px) {
      height: 398px !important;
      img {
        height: 600px !important;
        width: auto !important;
      }
    }
  }

}