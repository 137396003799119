/*=============================== CSS FOR MODAL / POPUP / LIGHTBOX ======================================================*/
.fancybox-close {
	width: 30px;
	height: 30px; 
	font-size: 0;
	top: 0;
	right: 0;
	background: none;
	border: 1px solid $mainColor_1;
	background: url('../images/close.png') no-repeat center $mainColor_1;
	text-align: center;
	transform: translate3d(0,0,0);
	transition: all .35s;
	&:hover {
		background: url('../images/close.png') no-repeat center lighten($mainColor_1, 15%);
	}
}
.fancybox-skin {
	border-radius: 0;
}
/*Newsletter Popup*/
.newsletter-popup {
	#newsletter-popup {
		height: 100%;
		position: relative;
		overflow: hidden;
	}
	.fancybox-skin {
		padding: 0 !important;
	}

	.fancybox-inner {
		overflow: visible !important;
	}

	.popup-image {
		float: left;
		display: block;
		position: absolute !important;
		left: 0; top: 0;
		width: 100%; height: 100%;
		img {
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
			width: auto;
			display: none;
		}
	}

	.block {

		h4 {
			background: none;
			border: none;
			padding: 0;
			margin: 0 0 30px;
			@include fontStyle--1();
			font-weight: bold; 
		color: $colorText_4;
		}
	}

	.popup-mail-content {
		padding: 65px 40px 40px;
		overflow: hidden;
	color: $colorText_4;
		text-align: center;
		position: relative !important;
		z-index: 10;
		@include fontStyle--4();
		@media (max-width: 767px) {
			padding: 50px 15px 30px;
			.title {
				&:after {
					display: none;
				}
			}
		}
	}

	.block_content {
		padding: 0 40px;
		margin-bottom: 40px;
		overflow: hidden;
		position: relative !important;
		text-align: center;
		z-index: 10;
		font-size: 0;
		input {
			vertical-align: top;
		}

		.inputNew {
			padding: 0 10px;
			float: none;
			width: 300px;
		}

		input[type="submit"] {
			@include btn-style-3();
			margin-left: 5px;
			text-transform: uppercase;
			border: none !important;
			transition: all 0.35s; 
			&:hover {
				//background: #fff;
			}
		}

		@media (max-width: 480px) {
			padding: 0 15px;
			.inputNew {
				width: 100%;
			}
			input[type="submit"] {
				margin-top: 10px;
				width: 30%;
				margin-left: 0;
			}
		}
	}
}
/*New Comment Popup*/
#new_comment_form {
	.page-subheading {
		margin-top: 0;
	}

	.product {
		img {
			width: 70px;
			height: auto;
			float: left;
			border: none;
			margin-right: 10px; 
		}

		.product_desc {
			.product_name {
				padding-top: 0;
				margin-bottom: 0;
				font-size: em(14px);
			color: $colorText_4;
				text-transform: uppercase;
			}	
		}
	}

	.new_comment_form_content {
		background: none;
		padding-left: 0;
		padding-right: 15px;
		@media (max-width: 767px) {
			padding-left: 15px;
		}
		label {	 
		}

		input {
			height: 45px;
		}

		#criterions_list {
			padding-bottom: 0;
		}

		.star_content {
			font-size: 0;
			float: none;
			line-height: 1;

			.cancel {
				float: none;
				display: inline-block;
			}

			.star {
				vertical-align: bottom;
				line-height: 16px;
			}
		}	
	}

	#new_comment_form_footer {
		padding-top: 15px;
		.fr {
			margin-top: -8px;
			font-size: em(14px);
			.closefb {
				font-weight: bold;
			}
		}
	}
}
/*Layer Cart Popup*/
.layer_cart_overlay {
	z-index: 100;
}
#layer_cart {
	border-radius: 0;
	border: 10px solid $mainColor_2;
	z-index: 102;
	padding: 0px;
	left: 8%;
	margin-right: 0;
	margin-left: 0;
	-webkit-animation-duration: 0.35s;
	animation-duration: 0.35s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-name: zoomIn;
  	animation-name: zoomIn;
  	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
	>div {
		overflow: hidden; 
		background: #fff;
		&.clearfix {
			padding-top: 20px;
			padding-bottom: 20px;
		}
	}
	.cross {
		&:before {
			display: none;
		}
		@extend .fancybox-close;
		right: -10px;
		top: -10px;
	}
	.layer_cart_product {
		padding: 0 20px 20px;
		.title {
			@include fontStyle--4();
			line-height: 1.4;
			padding-bottom: 13px;
			margin-bottom: 17px;
			border-bottom: 1px solid lighten($mainColor_2, 20%);
			line-height: 1;
			i {
				margin-bottom: 0px;
				font-size: em(18px);
				line-height: 1;
			}
		}
		.product-image-container {
			padding: 0;
			border: none;
			.layer_cart_img {
				border: 1px solid #ddd;
			}
		}
		.layer_cart_product_info {
			padding-top: 3px;
			.product-name {
				text-align: left;
				@include fontStyle--4();
			color: $colorText_3;
				margin-bottom: 0;
			}
			>div {
				margin: 5px 0;
				strong {
					@include fontStyle--6();
					font-weight: normal;
				}
				#layer_cart_product_quantity {
					@include fontStyle--4();
				}
			}
			#layer_cart_product_price {
			color: $colorText_3;
				@include fontStyle--4();
				padding-left: 10px;
			}
		}
	}
	.layer_cart_cart {
		border-color: lighten($mainColor_2, 20%);
		background: #fff;
		min-height: 0;
		position: relative;
		padding-top: 0;
		padding-left: 20px;
		padding-right: 20px;
		@media (max-width: 991px) {
			padding-top: 20px;
		}
		.title {
			@include fontStyle--4();
			line-height: 1;
			border-color: lighten($mainColor_2, 20%);
		}
		.button-container {
			padding: 30px;
			
			.continue {
				text-shadow: none;
				color: #fff;
				
				i{
					display: none;
				}
				
				@media (max-width: 767px) {
					width: 100%;
				}
			}
			a.btn {
				float: right;
				@include btn-style-3();
				i {
					display: none;
				}
				span {
					border: none;
					padding: 0;
				}
				@media (max-width: 767px) {
					float: left;
					width: 100%; 
				}
			}
		}
		.layer_cart_row {
			> span {
				font-weight: normal; 
				color: #373737;
				@include fontStyle--4();
				padding-left: 10px;
				&.ajax_block_cart_total {
				color: $colorText_3;
				}
			}
			strong {
				@include fontStyle--6();
				font-weight: normal;
				min-width: 130px;
				display: inline-block !important;
			}
		}
	}
	.crossseling {
		h2 {
			text-transform: uppercase;
			font-family: $fontHeading;
			font-size: em(18px);
			margin-top: 0;
		}
		.crossseling-content {
			border-color: #eee;
		}
		#blockcart_list {
			width: 100%;
			max-width: 100%;
			overflow: visible;
			.bx-wrapper {
				border: 1px solid #eee;

			}
			ul {
				@include clearfix();
				margin-bottom: 0;
				li{
					padding-bottom: 20px;
					text-align: center;
					border-right: 1px solid #eee;
					.product-image-container{
						border: none;
					}
					.product-name {
						margin-bottom: 0;
						text-align: center;
					}	
					.price {
						
						color: #ff3837;
					}
				}
			}
			.bx-prev, .bx-next {
				display: block !important;
				width: 30px !important;
				height: 30px !important;
				text-indent: 0!important;
				@extend .slideNav;
				top: 50%;
				margin-top: -15px!important;
			}
			.bx-prev {
				@extend .slidePrev;
				left: -15px!important;
				margin-left: 0 !important;
				&:before{
					color: #eee;

				}
				&:after{
					display: none !important;
				}

			}
			.bx-next {
				@extend .slideNext;
				right: -15px!important;
				margin-left: 0 !important;
				&:before{
					color: #eee;
				}
				&:after{
					display: none !important;
				}
			}
		}	
	}
}

/*Error Popup*/
.fancybox-error {
	padding-top: 30px;
	padding-bottom: 30px;
	min-width: 300px;
	white-space: normal;
}

/*Fancybox preloader*/
.ls-vpcontainer img {
	display: none;
}
#fancybox-loading, .ls-loading-container, .ls-vpcontainer .ls-playvideo{
	background: none!important;
	border: 3px solid transparent;
	border-top-color: $colorLoading_1;
	border-radius: 50%;
	width: 150px !important;
	height: 150px !important;
	margin-left: -75px !important;
	margin-top: -75px !important;
	animation: spin 2s linear infinite;
	transform: none;
	@media (max-width: 1229px) {
		width: 100px !important;
		height: 100px !important;
		margin-left: -50px !important;
		margin-top: -50px !important;
	}
	&:before {
		content: '';
		border-radius: 50%;
		border: 3px solid transparent;
		top: 5px;
		bottom: 5px;
		left: 5px;
		right: 5px;
		position: absolute;
		display: block;
		animation: spin 3s linear infinite;
		border-top-color: $colorLoading_2;
	}
	&:after {
		content: '';
		border-radius: 50%;
		border: 3px solid transparent;
		top: 15px;
		bottom: 15px;
		left: 15px;
		right: 15px;
		position: absolute;
		display: block;
		animation: spin 1.5s linear infinite;
		border-top-color: $colorLoading_3;	
	}
	div {
		display: none;
	}
}
.zoomPreload {
	font-size: 0; 
	position: relative;
	background: none;
	border: 3px solid transparent;
	border-top-color: $colorLoading_1;
	border-radius: 50%;
	width: 150px !important;
	height: 150px !important;
	animation: spin 2s linear infinite;
	@media (max-width: 1229px) {
		width: 100px !important;
		height: 100px !important;
	}	
	&:before {
		content: '';
		border-radius: 50%;
		border: 3px solid transparent;
		top: 5px;
		bottom: 5px;
		left: 5px;
		right: 5px;
		position: absolute;
		display: block;
		animation: spin 3s linear infinite;
		border-top-color: $colorLoading_2;
	}
	
	&:after {
		content: '';
		border-radius: 50%;
		border: 3px solid transparent;
		top: 15px;
		bottom: 15px;
		left: 15px;
		right: 15px;
		position: absolute;
		display: block;
		animation: spin 1.5s linear infinite;
		border-top-color: $colorLoading_3;	
	}
}