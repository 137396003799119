/*=========================================== CSS FOR FOOTER ===========================================================*/
.bc-top-footer {
	@include clearfix();	
	background: $mainColor_2;

	#newsletter_block_left {
		padding-left: 15px;
		padding-right: 15px;
		margin-bottom: 30px;
		left: 25%;
		@media (max-width: 1229px) {
			padding-left: 0px;
			padding-right: 0px;
		}
		@media (max-width: 767px) {
			left: 0%;
			padding-left: 15px;
			padding-right: 15px;
		}

		.block_content {
			position: relative;
			.form-group {
				&:before {
					content:"\f0e0";
					font-family: $fontIcon;
					font-size: em(16px);
					display: inline-block;
					color: $colorText;
					position: absolute;
					left: 10px;
					top: 11px;
				}
			}
		}
		h4 {
			border: none;
			margin-top: 0;
			margin-bottom: 0;
			padding-top: 30px;
			padding-bottom: 18px;
			background: none;
			@include fontStyle--3();
			color: $colorText_2;
			text-align: center;
			line-height: 1.2;
			span {
				display: block;
				@include fontStyle--4();
				font-weight: bold;
				text-transform: none;
				padding-top: 5px;
			}
			+div {
				padding-left: 80px;
				padding-right: 80px;
				@media (max-width: 1229px) {
					padding-left: 40px;
					padding-right: 40px;
				}
				@media (max-width: 767px) {
					padding-left: 0;
					padding-right: 0;
				}
				.form-group {
					position: relative;
					margin-bottom: 0;
				}
			}
		}
		input.newsletter-input {
			height: 40px;
			line-height: 40px;
			border: 2px solid $mainColor_1;
			padding-left: 30px;
			padding-right: 105px;
			max-width: 100%;
			border-radius: 0;
			@media (max-width: 480px) {
				border-radius: 3px 3px 0 0;
				padding-right: 30px;
			}
		}
		button[type="submit"] {
			border: none;
			border-radius: 0;
			font-family: $fontHeading;
			font-size: em(16px);
			text-transform: uppercase;
			background: $colorBtn_1;
			color: $colorText_2;
			height: 40px;
			padding: 0 15px;
			position: absolute;
			right: 0;
			top: 0;
			&:hover {
				background: lighten($colorBtn_1, 5%);
				color: #fff !important;
			}
			@media (max-width: 480px) {
				position: relative;
				width: 100%;
				border-top-left-radius: 0;
				border-radius: 0 0 3px 3px;
			}
		}
	}
}

.footer-container {
	background: none;
	background-color: #fff;
	padding-top: 35px;
	padding-bottom: 40px;
	border-bottom: 1px solid #f3f3f3;
	@media (max-width: 767px) {
		padding-top: 40px;
		padding-bottom: 30px;
	}

	section {
		ul {
			margin-bottom: 0;
		}
		h4 {
			@include fontStyle--4();
			text-transform: uppercase;
			color: $colorText_4;
			line-height: 1;
			margin-top: 0;
			margin-bottom: 20px;
			position: relative;
			a {
				@include fontStyle--4();
			color: $colorText_4;	
			}
			&:after {
				content: "";
				display: none;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 80px;
				height: 1px;
				background-color: #f3f3f3;
			}
			@media (max-width: 991px) and (min-width: 768px) {
				font-size: em(20px);
				a {
					font-size: inherit;
				}
			}
			@media (max-width: 767px) {
				width: 100%;
				&:after {
					display: none !important;
				}
				&:before {
					content: "\f13a";
					font-family: $fontIcon;
					font-size: 13px;
					display: block;
					position: absolute;
					right: 0;
					top: 3px;
				}
				&.active {
					color: $colorText_3;
					a {
						color: inherit;
					}
					&:before {
						content: "\f139";
					}
				}
			}
		}
		&:not(#block_contact_infos) {
			ul {
				li {
					padding: 2px 0;
					line-height: 2.1;
					a {
					color: $colorText_4;
						&:before {
							content: "\f0da";
							font-family: $fontIcon;
							display: none;
							font-size: 16px;
							color: inherit;
							margin-right: 7px;
						}
						&:hover {
							text-decoration: underline;
							color: $colorText_3;
							font-weight: bold;
							padding-left: 0px;
						}
					}
				}
			}	
		}
		&#block_contact_infos {
			> div {
				padding-left: 0;
				ul {
					li {
						padding-bottom: 15px;
						font-size: em(12px);
					color: $colorText_4;
						i {
							display: none;
						}
						span {
						color: $colorText_3;
							font-weight: bold;	
						}
					}
				}
			}
		}
	}

	#social_block {

		h4 {
			@include fontStyle--4();
			text-transform: uppercase !important;
		color: $colorText_4;
			line-height: 1 !important;
			margin-top: 0;
			margin-bottom: 20px !important;
			position: relative;
			font-size: em(18px) !important;
			float: none !important;
			a {
				@include fontStyle--4();
			color: $colorText_4;	
			}
			&:after {
				content: "";
				display: none;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 80px;
				height: 1px;
				background-color: #f3f3f3;
			}
			@media (max-width: 991px) and (min-width: 768px) {
				font-size: em(20px);
				a {
					font-size: inherit;
				}
			}
			@media (max-width: 767px) {
				width: 100%;
				&:after {
					display: none !important;
				}
				&:before {
					content: "\f06c";
					font-family: $fontIcon;
					font-size: 14px;
					display: block;
					position: absolute;
					right: 0;
					top: 3px;
				}
				&.active {
				color: $colorText_3;
				}
			}
		}

		ul {
			@include clearfix();
			@media (max-width: 767px) {
				text-align: center;
				padding-top: 30px;
			}
			li {
				display: block;
				float: none !important;
				text-align: left !important;
				/*width: 40px;
				height: 40px;	
				border-radius: 3px;
				padding-bottom: 0 !important;		
				margin-right: 10px;
				border: 1px solid #f3f3f3;
				background-color: transparent;*/
				span {
					display: block !important;
					font-size: em(12px) !important;
					color: inherit !important;
				}
				a {
					display: block;
					width: 100%;
					height: 100%;
				color: $colorText_4 !important;
					padding-top: 0px;
					&:before {
						margin-right: 0 !important;
						font-size: 14px !important; 
						line-height: 1;
						display: none !important;
					}

					&:hover {
					color: $colorText_3 !important;
					}
				}
				@media (max-width: 991px) and (min-width: 768px) {
					width: 30px;
					height: 30px;
					margin-right: 5px;
					a {
						padding-top: 0px;
						&:before {
							font-size: 12px !important;
						}
					}
				}
				@media (max-width: 767px) {
					float: none !important;
					display: inline-block;
				}
			}
		}

	} 

	.bc_tags_block {
		h4 {
			text-transform: uppercase;
		color: $colorText_4;
			
			margin-bottom: 42px;
		}

		a {
			text-transform: uppercase;
			font-size: em(11px);	
			border: 1px solid #ededed;
			padding: 0 18px;
			float: left;
			margin-right: 10px;
			margin-bottom: 12px;
			min-height: 30px;
			padding-top: 5px;
			@media (max-width: 991px) {
				padding-right: 5px;
				padding-left: 10px;
				padding-right: 10px;
			}

			&:hover {
				background: #373737;
				color: #fff;
			}
		}
	}
}

.bc-bottom-footer {
	background: $mainColor_2; 
	padding: 18px 0 15px;
	.copy-right {
		text-transform: none;
		color: $colorText_2; 
		@media (max-width: 767px) {
			text-align: center;
			margin-bottom: 10px;
		}
	}
	.bc-bewaer-ft {
		text-align: right;
		@media (max-width: 767px) {
			text-align: center;
		}
		a {
			display: inline-block;
		}
	}
}