/*=====================================CSS FOR PRODUCT DETAIL PAGE==================================================*/
.product {
	div.star_on:after,
	div.star:after {
	color: $colorText_3 !important;
	}
	.top-hr {
		display: none;
	}
	.primary_block {
		@include clearfix();
		padding-top: 30px;
	}
	.pb-left-column {
		padding-left: 0;
		@media (max-width: 767px) {
			padding-right: 0;
		}
		#image-block {
			border: 1px solid #ddd;
			padding: 0;
			width: 100%;
			.zoomPad, img {
				width: 100%;
				height: auto;
			}
			.new-box, .sale-box {
				left: 0;
				&.new-box {
					top: 0;
				}
				&.sale-box {
					top: 25px;
				}
			}

			.zoomPad {
				overflow: hidden;
			}
		}

		#views_block {
			position: relative;
			margin-top: 30px;
			margin-left: -5px;
			margin-right: -5px;

			.view_scroll_spacer {
				margin: 0;
			}
			
			#thumbs_list  {
				width: 100%;
				
				li {
					text-align: center;
					float: left;
					height: auto;
					border: none;
					padding: 0 5px;
					margin: 0;
					width: 92px;
					@media (max-width: 1199px) {
						width: 101px;	
					}
					@media (max-width: 991px) {
						width: 115px;
					}
					@media (max-width: 767px) {
						img {
							width: 100%;
							display: inline-block;
						}
					}
					a{
						border: 1px solid #ddd;
						display: block;
						position: relative;
						@include clearfix();
						transition-duration: 0.2s;
						&:before {
							content:'';
							display: block;
							position: absolute;
							left: 0;
							top: 0;
							right: 0;
							bottom: 0;
							border-top: 2px solid $mainColor_1;
							border-left: 2px solid $mainColor_1;
							transition: all .35s;
							transform: scale(0,0);
							transform-origin: 0 0;
						}
						&:after {
							content:'';
							display: block;
							position: absolute;
							left: 0;
							top: 0;
							right: 0;
							bottom: 0;
							border-bottom: 2px solid $mainColor_1;
							border-right: 2px solid $mainColor_1;
							transition: all .35s;
							transform: scale(0,0);
							transform-origin: 100% 100%;
						}
						&.shown, &:hover {
							border-color: $mainColor_1;
							border-width: 5px;
							/*&:before, &:after {
								transform: scale(1,1);
							}*/
						}
					}
					img {
						border: none;
						float: none;
						width: 100%;
						height: auto;
					}
				}
				ul#thumbs_list_frame {
					height: auto;
					
				}
			}

			#view_scroll_left, #view_scroll_right {
				width: 30px;
				height: 30px;
				padding: 0;
				position: absolute;
				top: 50%;
				margin-top: -20px !important;
				z-index: 20;
				background: $mainColor_2;
				@include box-sizing();
				&:before {
					font-size: 15px;
					line-height: 30px;
				}
				&:hover {
					background: lighten($mainColor_2, 20%);
				}
			}

			#view_scroll_left {
				left: -8px;			
				@extend .slideNav;
				@extend .slidePrev;
				@media (max-width: 767px) {
					left: -8px;
				}
			}

			#view_scroll_right { 
				right: -8px;
				@extend .slideNav;
				@extend .slideNext;
				@media (max-width: 767px) {
					right: -8px;
				}
			}
		}


	}
	.pb-center-column {
		padding-left: 15px;
		@media (max-width: 767px) {
			padding-left: 0;
			padding-right: 0;
		}
		h1 {
			@extend .page-subheading; 
			text-transform: uppercase;
			margin-top: 0;
		}

		.content_prices {
			padding-left: 0;
			padding-right: 0;
			padding-top: 0;
			position: relative;

			.our_price_display {
				display: inline-block;
				.price {
					@include fontStyle--3();
				color: $colorText_3;
					font-weight: normal;
					padding-right: 5px;
				}
			}
			
			#old_price {
				margin-bottom: 0;
				padding-bottom: 0;
				.price {
					@include fontStyle--4();
					margin-top: 3px;
					display: block;
				}
			}

			#reduction_percent {
				display: none !important;
				@extend .price-percent-reduction;
				line-height: 24px;
				position: relative;
				left: 0; top: 0;
				float: left;
				margin-bottom: 0;
			}
		}

		#availability_statut {
			#availability_value {
				margin-left: 0;
				margin-right: 10px;
				background: none;
				border: none;
				background: $mainColor_1;
				border-radius: 4px;
				text-transform: uppercase;
				font-size: em(10.5px);
				font-family: $fontHeading;
				padding: 3px 6px 2px;
				line-height: 1;
			}
		}

		#pQuantityAvailable {
			#quantityAvailable, #quantityAvailableTxtMultiple {
				@include fontStyle--5();
				font-weight: normal;
			}	
		}

		#product_comments_block_extra {
			@include clearfix();
			margin-top: 0;
			margin-bottom: 0;
			padding: 0;
			background: none;
			border: none;
			.comments_note{
				float: left;
				margin-right: 20px;
			}
			.comments_advices {
				clear: none;
				float: left;
				margin-top: -4px;
				.reviews, .open-comment-form {
					border-right: none;
					padding-right: 0;
					margin-right: 10px;
				color: $colorText_4;
					font-weight: normal;
					
				}

				a {
					line-height: normal;
					&:hover {
					color: $colorText_4;
						text-decoration: underline;
					}
					&:before {
						color: inherit;
						float: left;
					}
				}
				
				li {
					line-height: normal !important;
					&:after {
						content:"|";
						display: inline-block;
						margin-right: 10px;

					}
					&:nth-child(2) {
						&:after {
							display: none;
						}
					}
					@media (max-width: 991px) {
						display: inline-block;
					}
				}
			}
		}

		#short_description_block {
			padding-top: 20px;
			padding-bottom: 20px;
			margin-top: 10px;
			margin-bottom: 10px;
			border-bottom: 1px solid lighten($mainColor_2,40%);
			border-top: 1px solid lighten($mainColor_2,40%);
			display: inline-block;
			width: 100%;
		color: $colorText_4;
			@include fontStyle--6();
			#short_description_content {
				padding-bottom: 0;
				p {
					margin-bottom: 0;
				}
			}
		}

		#buy_block {
			padding-bottom: 20px;
			border-bottom: 1px solid lighten($mainColor_2,40%);
		}

		.box-info-product {
			background: none;
			border: none;

			.box-cart-bottom {
				@include clearfix();
				margin-top: 20px;
				margin-bottom: 0px;
				-webkit-box-shadow: none;
				box-shadow: none;
				clear: both;
				width: 100%;
				>div {
					display: inline-block;
					float: left;
				}
			}

			.product_attributes {
				-webkit-box-shadow: none;
				box-shadow: none;
				padding: 0;
				@include clearfix();

				label {
					text-align: left;
					font-weight: normal;
					margin-top: 10px;
					float: left;
					width: 100px;
					@include fontStyle--4();
				color: $colorText_4;
				}

				.attribute_list {
					float: none;
					ul {
						clear: none !important;
					}
					div.selector {
						/*height: 40px;
						>span, select {
							height: 40px;
							line-height: 40px;
						color: $colorText_4;
							&:after {
								width: 38px;
								height: 38px;
								line-height: 38px;
							}
						}*/
					}
				}

				fieldset {
					padding-bottom: 20px;
				}
			}

			#color_to_pick_list {

				li {
					float: left;
					margin: 0;
					width: 32px;
					height: 32px;
					padding: 3px;
					border: 1px solid transparent;

					a {
						width: 100%;
						height: 100%;
						&[name*="White"] {
							border: 1px solid #ccc;	
						}
					}

					&.selected {
						border-color: $mainColor_1;
					}
				}
			}

			#quantity_wanted_p {
				float: left;
				position: relative;

				.btn {
					margin-left: 0;
					margin-right: 1px;
					border: none;
					position: relative;
					width: 34px;
					height: 34px;
					line-height: 33px;
					border-radius: 4px;
					overflow: hidden;
					float: right;
					
					span {
						width: 100%;
						height: 100%;
						padding: 0;
						border: none;
						background-image: none;
						background-color: $mainColor_2;
						-webkit-transition: background .3s;
						-o-transition: background .3s;
						transition: background .3s;
						i {
							color: #fff;
							font-size: 14px;
							line-height: 1;
						}
					}
					&.button-minus {
						margin-right: 0;
						i:before {
							content:"\f068";
						}				
					}
					&.button-plus {
						i:before {
							content: "\f067";
						}
					}
					&:hover {
						span {
							background: $mainColor_1;
							i {
								//color: $mainColor_2;
							}
						}
					}
				}
				input {
					height: 34px;
					padding-right: 30px;
					background: #fff;
					text-align: center;
					//border: 1px solid #f3f3f3 !important;
					border-radius: 4px;
					font-size: em(18px);
				color: $colorText_4;
					margin-right: 1px;
				}
			}
			#add_to_cart {
				padding: 0;
				float: left;
				margin-right: 1px;
				margin-bottom: 0;
				button {
					@include btn-cart();
					height: 38px;
					background: none;
					padding: 0;
					border-radius: 0;
					span {
						font-size: em(16px) !important;
					}
				}
			}

			.functional-buttons {
				white-space: nowrap;
				font-size: 0;
				padding-top: 0px;
				line-height: 1;
				.buttons_bottom_block, .compare {
					padding: 0;
					margin-bottom: 0;
					display: inline-block;
				}
				#wishlist_button_nopop, .add_to_compare {
					@include btn-functional-general();
					/*width: 40px;
					height: 40px;
					line-height: 40px;
					float: left;
					&:before {
						font-size: em(14px) !important;
					}*/ 
				}
				#wishlist_button_nopop {
					float: left;
					@include btn-functional-content("\f004"); 
					&.checked {
						@include btn-functional-content("\f184");
						background: $colorViewLabel;	
					}
					margin-right: 1px;
				}
				.add_to_compare {
					float: left;
					@include btn-functional-content("\f080"); 
					&.checked {
						@include btn-functional-content("\f200");
						background: $colorViewLabel;	
					}
				}
			} 
		}

		.socialsharing_product {
			clear: both;
			padding-top: 15px;
			&:before {
				content: "Share";
				display: none;
				text-transform: uppercase;	
				font-size: em(14px);
				font-weight: bold;		
			color: $colorText_4;
				float: left;
				width: 100px;
				margin-top: 10px;
				@media (max-width: 767px) {
					margin-right: 10px;
				}
			}
			
			button {
				background: #fff;
				border: none;
				@include fontStyle--6();
				margin-right: 0px;
				text-align: center;
				padding: 5px 5px;
				&:after {
					border-radius: 50% !important;
				}
				i {
					display: inline-block !important;
					font-size: 14px;
				}
				&.btn-twitter {
					i {
						color: #00aaf0;	
					}
				}
				&.btn-facebook {
					i {
						color: #435f9f;	
					}
				}
				&.btn-google-plus {
					i {
						color: #e04b34;	
					}
				}
				&.btn-pinterest {
					i {
						color: #ce1f21;	
					}
				}
				&:hover {
					color: $colorText_2;
					i {
						color: $colorText_2;
					}
					&.btn-twitter {
						background: #00aaf0 !important;	
						&:after {
							background: #00aaf0 !important;	
						}
					}
					&.btn-facebook {
						background: #435f9f !important;	
						&:after {
							background: #435f9f !important;	
						}
					}
					&.btn-google-plus {
						background: #e04b34 !important;	
						&:after {
							background: #e04b34 !important;	
						}
					}
					&.btn-pinterest {
						background: #ce1f21 !important;	
						&:after {
							background: #ce1f21 !important;	
						}
					}
				}	
			}
		}
	}
	.pb-right-column {
		@media (max-width: 991px) {
			display: none;
		}
		.product-custom-content {
			h3 {
			color: $colorText_4;
				margin-top: 0;
				margin-bottom: 30px;
			}
			.feature {
				@include clearfix();
				margin-bottom: 20px;
				.icon {
					width: 55px;
					height: 55px;
					margin-right: 10px;
					float: left;
					line-height: 55px;
					border-radius: 55px;
					text-align: center;
					background-color: $mainColor_1;
					color: #fff;
					font-size: 18px;
				}
				.custom-title {
					@include fontStyle--5();
				color: $colorText_4;
					font-family: $fontHeading;
					margin-top: 5px;
					margin-bottom: 5px;
				}
			}	 
		}
	}

	#more_info_block {
		#more_info_tabs {
			padding: 13px 20px;
		    background: #fff;
		    border-top: 1px solid #e8e8e8;
		    border-right: 1px solid #e8e8e8;
		    border-bottom: 4px solid $mainColor_1;
		    border-left: 1px solid #e8e8e8;
		    text-transform: uppercase;
		    @media (max-width: 767px) {
		    	padding: 10px;
		    }
			li {
				display: inline-block;
				float: left; 
				a {
					@include fontStyle--4();
					text-transform: uppercase;				
					margin-right: 40px;
					display: block;
					color: $colorText_4;
					-webkit-transition-duration: 0s;
					-o-transition-duration: 0s;
					transition-duration: 0s;
					&.selected, &:hover {
					color: $colorText_3;
					}
				}
				@media (max-width: 767px) {
					
					a {
						display: inline-block;
						font-size: 16px;
						margin-right: 10px;
					}
				}
			}
		}

		#more_info_sheets{
			border: 1px solid #e8e8e8;
			padding: 20px;
		color: $colorText_4;
		}

		.content-tab {
			padding: 0;
		}

		.table-data-sheet {
			border-color: $mainColor_2;

			tr,td {
				border-color: $mainColor_2;
			}

			tr {
				
				&:first-child {
					
				}
			}

			td {
				&:first-child {
					@include fontStyle--6();
					font-weight: bold;
					text-transform:uppercase
				}
			}
		}
	}
}

.product.content_only {
	border: 10px solid $mainColor_2;
	.primary_block.row {
		@include clearfix();
		margin-left: 0;
		margin-right: 0;
		padding-top: 30px;
		padding-left: 15px;
		padding-right: 15px;
	}

	#scroll-to-top {
		display: none;
	}
	.pb-left-column {
		padding-bottom: 30px;
		padding-left: 15px;
		float: left; 
		@media (max-width: 1199px) {
			width: 40%;
		}
		@media (max-width: 991px) {
			width: 50%;
		}
		@media (max-width: 767px) {
			padding-right: 15px;
			padding-left: 15px;	
			width: 50%;
		}

		#views_block {
			#thumbs_list {
				li {
					@media(max-width: 1199px) {
						width: 98px;
					}
					@media(max-width: 991px) {
						width: 107px;
					}
				}
			}
			#view_scroll_right {
				@media(max-width: 1199px) {
					top: 0;
					right: 0;
				}
			}
			#view_scroll_left {
				@media(max-width: 1199px) {
					top: 0;
					left: 0;
				}
			}
		}

	}
	.pb-center-column {
		padding-left: 15px;
		float: left;
		@media (max-width: 1199px) {
			width: 60%;
		}
		@media (max-width: 991px) {
			width: 50%;
		}
		@media (max-width: 767px) {
			width: 50%;
			padding-left: 15px;
		}
		.box-info-product {
			
			.functional-buttons {
				@media (max-width: 320px) {
					float: left;
				}
			}

			.box-cart-bottom {
					
			}
				
		}	
	}
	.pb-right-column {
		@media (max-width: 1229px) {
			display: none;
		}
	}
}

#index .product {
	display: none;
}
/*PRODUCT RELATED SECTION*/
.page-product-box {
	overflow: visible;
	padding-top: 30px;
	@include show-navigation();
	.page-product-heading {
		@include fontStyle--4();
		line-height: 1;
		color: $colorText_3;
		background: none;
		padding: 13px 20px;
		background: #fff;
	    border-top: 1px solid #e8e8e8;
	    border-right: 1px solid #e8e8e8;
	    border-bottom: 4px solid $mainColor_1;
	    border-left: 1px solid #e8e8e8;
	    text-transform: uppercase;
	}

	.block_content{
		margin-left: -15px;
		margin-right: -15px;
	}

	.owl-controls {
		position: static !important;
		.owl-prev,
		.owl-next {
			background: $mainColor_2;
			position: absolute;
			top: 50%;
			margin: -20px 0 0 0 !important;
			width: 40px;
			height: 40px;
			&:before {
				line-height: 40px;
				font-size: 14px;
			}
			&:hover {
				color: $colorText_2 !important;
				background: $mainColor_1;
			}
		}
		.owl-prev {
			left: 0px !important;
		}
		.owl-next {
			right: 0px !important;
		}
	}

	.item {
		transform: translate3d(0,0,0);
		.product-container {
			border: 1px solid #e8e8e8;
			text-align: left;
			padding: 15px 15px 10px;
			@media (max-width: 991px) and (min-width: 768px) {
				padding: 10px 5px 10px;
			}
			.product_desc {
				.product-image {
					border: none;
					padding: 0;
				}
			}
			.product-name {
				a {
					@include fontStyle--4();
				color: $colorText_3;
					margin: 10px 0;
					&:hover {
					color: $colorText_4;
						text-decoration: underline;
					}
				}
			}
			.price {
				@include fontStyle--3();
			color: $colorText_4;
			}
			.ajax_add_to_cart_button {
				@include btn-cart();
				padding-top: 10px;
				padding-bottom: 20px;
				@media (max-width: 410px) {
					span {
						display: none;
					}
				}
			}
		}
	}
	.owl-controls {
		.owl-prev, .owl-next {
			position: absolute;
			top: 50%;
		}
		.owl-prev {
			left: -20px;
		}
		.owl-next {
			right: -20px;
		}
	}
} 
