$checker_top: 0px;
.heading-counter {
	margin-bottom: 0;
	font-family: $fontHeading;
	#summary_products_quantity {
		font-size: em(14px);
	color: $colorText_3;
		font-weight: bold;
	}
}
/*Step*/
ul.step {
	border-radius: 3px;
	margin-top: 25px;
	li {
		position: relative;
		border: none;
		border-right: 1px solid #ddd !important;
		font-family: $fontHeading_2;
		@media (max-width: 767px) {
			border-right: none !important;
		}
		&:last-child {
			border-right: none !important;
		}
		a, span {
			padding-right: 35px !important;
			font-size: em(15px);
		}
		&:before {
			display: block;
			position: absolute;
			right: 15px;
			top: 15px;
			font-family: $fontIcon;
		color: $colorText_4;
			font-size: 15px;	
		}
		&.step_todo {
			background: #f3f3f3;
			border-radius: 0;
			&:before {
				content: "\f04e";	
			}
			span, a {
				border-radius: 0; 
				border: none;
				text-shadow: none;
			color: $colorText_4;
				text-transform: uppercase;
				
				&:after{
					display: none;
				}
			}	
		}
		&.step_current, &.step_done {
			background: $mainColor_2;
			border-radius: 0;
			a, span {
				text-shadow: none;
				border: none;
				text-transform: uppercase;
				&:after{
					display: none; 
				}
			}

		}
		&.step_current {
			&:before {
				content: "\f0c6";
				color: #fff;
			}
			span {
				font-size: em(15px);
			}
		}
		&.step_done {
			background: $mainColor_1;
			&:before {
				content: "\f046";
				color: #fff;
				font-style: normal;
			}
			em {
			}
		}
	}
}
/*cart summary table*/
#cart_summary {
	tr {
		td, th {
			border-color: #ddd;
			padding: 10px 15px;
		}
	}
	tbody {
		td {
			&.cart_product {
				padding: 15px;
				img {
					border: none;
				}
			}
			&.cart_avail {

				span {
					@extend .stock-availability;
				}
			}
			&.cart_delete {
				.cart_quantity_delete {
					i:before {
						content: "\f146";
					}
					&:hover {
						color: $colorText_3;
					}
				}
			}
		}
	}
	tfoot {
		tr {
			background: none;
			td {
				padding: 10px 15px;
				&.text-right {
					font-weight: normal;
				}
				&.total_price_container span {
					font-family: $fontHeading;
				}
			}
		}
	}
	>thead
	{
		>tr{
			>th{
				background: $mainColor_1;
				color: $colorText_2;
				@include fontStyle--5();
				text-transform: uppercase;
			}
		}
	}
	.price {
		color: $colorText_4;
		font-family: $fontHeading;
		font-size: em(18px);
		.old-price {
			font-size: em(14px);
			margin-top: 5px;
		}
		.price-percent-reduction {
			display: none;
			border-radius: 0;
			height: 30px;
			line-height: 30px;
			padding: 0;
			width: auto;
			padding: 0 10px;
			position: relative;
			float: left;
			top: 0;
			right: 0;
			font-size: em(14px);
		}
	}
	#total_price {
		@include fontStyle--4();
	color: $colorText_3;
		font-weight: bold;
	}
	.cart_quantity {
		input, > span {
		color: $colorText_4;
			font-size: em(16px);
		}
		> span {
			font-size: em(18px);
		}
	}
	.cart_quantity_button {
		margin-top: 5px;
	}
	.btn.button-plus, .btn.button-minus {
		color: $colorText_2;
		border: none;
		overflow: hidden;
		@media (max-width: 767px) {
			display: inline-block;
			float: left;
		}
		span {
			background: $mainColor_2;
			border-radius: 4px;
		}
		&:hover {
			span {
				background: $mainColor_1;
			}
		}
	}
	.btn.button-plus {
		float: right;
	}	
}
.cart_navigation {
	padding-top: 30px;
	.standard-checkout,.button-exclusive, .btn {	
		margin: 0;
		@media (max-width: 767px) {
			display: block;
			margin-bottom: 10px;
			float: left;
			width: 100%;
		}
		i.right, i.left, i {
			color: inherit;
			font-size: 12px;
			line-height: 1;
			vertical-align: 0px;
			display: inline-block;
		}
		span {
			font-size: em(14px);
		}
	}
	.standard-checkout, [type="submit"] {
		@include btn-style-3();	
		span{
			border: none;
			padding: 0;
		}
		i {
			display: inline-block !important;
		}
	}
	.button-exclusive {
		@include btn-style-2();	 
	}
}
#order, #authentication, #address, #identity,  #addresses, #my-account{
	.top-breadcrumb {
		padding-bottom: 20px;
	}
	label {
		margin-bottom: 10px;
	}	
	.form-group {
		margin-top: 0px;
		margin-bottom: 20px;
	}
	.checkbox {

		.checker {
			margin-top: $checker_top;
		}
	}

	.footer_links {
		border-color: #ddd;
	}

}
#order {
	.addresses {
		div.checker {
			margin-top: $checker_top;
		}
		.address_add .btn {
			@include btn-style-3();
		} 
		.address_add.submit {
			margin-top: 20px;
		}	
	}
}
/*Payment module*/
p.payment_module {
	a {
		border-radius: 0;
		background-color: transparent;
		border-color: #eee;
		&:hover {
		color: $colorText_3;
			span {
				color: inherit;
			}
			&:after {
				color: inherit;
			}
		}
		background-position: 15px 50%;
	}
}
/*Authentication*/
#authentication {
	.box {
		border: none;
		background: none;
		padding-left: 0;
		padding-right: 0;
	}

	h3 + .required {
		display: none;
	}

	#create-account_form {
		border: 1px solid #e8e8e8;
		padding: 20px;
		box-shadow: 0 1px 1px rgba(0,0,0,.05);
		p {
			@include fontStyle--6();
		color: $colorText_4;
		}
		@media (max-width: 767px){
			min-height: 0;
		}
	}

	#login_form {
		border: 1px solid #e8e8e8;
		padding: 20px;
		box-shadow: 0 1px 1px rgba(0,0,0,.05);
		button#SubmitLogin	{
			@include btn-style-3();	
		}
		.lost_password {
			a {
				color: red;
				&:hover {
				color: $colorText_3;
					text-decoration: underline;
				}
			}
		}
	}

	input.form-control {
		@media (max-width: 480px) {
			width: 100%;
			max-width: 100% !important;
		}
	}
}
/*Adress*/
#address {
	.top-breadcrumb {
		padding-bottom: 20px;
	}
	
	#adress_alias {
		margin-bottom: 40px;
	}

	.footer_links {
		.btn {
			@extend .btn-style-1;
		}
	}

}
/*My account*/
#my-account {
	#center_column {
		overflow: hidden;
	}
	ul.myaccount-link-list {
		li {
			a {
				@include fontStyle--5();
				border-radius: 3px;
				background: none;
				border-color: $mainColor_1;
				color: $colorText_4;
				i {
					text-shadow: none;
					border-radius: 0;
					border: none;
					color: inherit;
					font-size: em(18px);
					padding: 15px;
				}
				span {
					font-weight: normal;
					border: none;
					border-left: 1px solid $mainColor_1;
					color: inherit;
					text-shadow: none;
					border-radius: 0;
				}
				&:hover{
					background: $mainColor_1;
					color: $colorText_2;
					border-color: #ddd;  
					span {
						border-left: 1px solid #fff;
					}
				}	
			}
		}	
	}	
}
/*Product Compare*/
#products-comparison {
	#product_comparison {
		border-color: #f3f3f3;
		.remove {
			a {
				font-size: 20px;
				line-height: 1;
			color: $colorText_4;
				&:hover {
					color: red;
				}
			}
			.icon-trash:before {
				content: "\f146";
			}
		}
		tr {
			td{
				line-height: 1;
				border-color: #ddd;
				padding: 10px 15px;
				&.comparison_infos{
					position: relative;
					.btn:not(.ajax_add_to_cart_button) {
						@include btn-style-3();
					}
				}
				&.td_empty, &.feature-name {
					@include fontStyle--5();
				color: $colorText_4;
					strong {
						font-weight: normal;
					}
				}
				&.compare_extra_information, &.feature-name.td_empty {
					span {
						@include fontStyle--4();
						font-weight: normal;
					color: $colorText_3;
					}
				}
				.price-percent-reduction {
					top: 54px;
					margin-top: 15px;
				}
				.product-rating {
					div.star:after,	
					div.star.star_on:after {
					color: $colorText_3;
					}
				}
			}
		}
		.product-image-block {
			width: 100%;
			.product_image {
				border: none;
				img {
					width: 100%;
				}
			}	
		}
		h5 {
			min-height: 0;
			padding-bottom: 0;
		}
		.button-container {
			font-size: 0;
			a {
				margin: 0;
				&.lnk_view {
					@include btn-style-3();
					border-radius: 0 !important;
					margin-top: 0;
					height: 38px !important;
					margin-left: 1px;
					span {
						padding-top: 3px;
						display: inline-block;
						margin-right: 0;
					}
				}
			}
			.ajax_add_to_cart_button {
				@include btn-cart();
				display: inline-block;
				margin-top: 0;
				vertical-align: top;
			}	
		}
	}
	.table-responsive {
		margin-top: 30px;
	}
	.ajax_block_product {
		.remove {
			position: absolute;
			right: 10px;
			top: 10px;
			z-index: 10;
		}
		.product-image-block {
			.product_image {
				padding: 0;
			}
			.new-box, .sale-box {
				left: -15px;
			}
			.new-box {
				top: -10px;
			}
			.sale-box {
				top: 15px;
			}
		}	
	}
}

#password {
	#center_column {
		.box {
			padding-top: 20px;
		}
	}
	.footer_links {
		border-color: #ddd;
		.button {
			@include btn-style-3();
		}
	}
}

/*ONESTEP CHECKOUT*/
#order-opc{
	div.selector {
		width: 100%;
		span, select {
			width: 100%;
		}
	}
	.opc-wapper {
		margin-left: -15px;
		margin-right: -15px;
		@include clearfix();
		.page-heading {
			@include fontStyle--3();
			text-align: left;
			margin-bottom: 20px !important;
			border-bottom: 2px solid $mainColor_1;
			&:before,
			&:after {
				display: none;
			}
		}
	}
	#openLoginFormBlock {
	color: $colorText_4;
		&:hover {
		color: $colorText_3;
		}	
	}
	.form-group {
		margin-bottom: 10px;
		.form-control {
			width: 100%;
			max-width: 100%;
		}
	} 
	#opc_new_account,
	#carrier_area,
	.opc_payment_area,
	.opc-account-area  {
		width: 50%;
		float: left;
		padding: 0 15px;

		@media (max-width: 767px) {
			width: 100%;
		}
	}
	#opc_new_account .opc-account-area {
		width: 100%;
	}
	.opc-add-save{
		border-color: #f3f3f3;
	}

	div.radio-inline {
		label {
			margin-bottom: 0;
		}
	}
	p.warning {
		font-weight: normal !important;
	}
}
/*OUR STORES*/
#stores {
	.radius-input {
		label {
			padding-top: 5px;	
		}
	}	
}
/*ABOUT US*/
.cms-about-us {
	#center_column {
		ul {
			list-style: none;
			padding-left: 0;
			li {
				border-top: 1px solid #f3f3f3 !important;
				color: $colorText_3;
				padding-left: 0;
				&:first-child {
					border: none !important;
				}
				.icon-ok {
					padding-right: 10px !important;
					width: auto;
					&:before {
						content: "\f058";
						font-style: normal;
					    font-family: "FontAweSome";
					    display: inline-block;
					    font-size: 12px;
					    color: inherit;
					    float: left;
					    position: relative;
					    top: 4px;
					}
				}
			}
		}
		.cms-block, .cms-box {
			border: 1px solid #e8e8e8;
		    padding: 20px;
		    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);	
		}
	}	
}
/*CONTACT*/
#contact {
	.contact-info-icons {
		padding-top: 30px;
	}
	.contact-info {
		padding-bottom: 0px;
		text-align: center;
		@media (max-width: 767px) {
			text-align: left;
			padding-bottom: 20px;
		}
		.icon {
			margin-right: 10px;
			float: none;
			width: 30px;
			margin-bottom: 10px;
			img {
				display: none;
			}
			&:before {
				display: inline-block;
				font-family: $fontIcon;
				font-size: 18px;
			color: $colorText_3;
			}
			&.icon-phone-icon:before {
				content: "\f095";
			}
			&.icon-mailicon:before {
				content: "\f0e0";
			}
			&.icon-faxicon:before {
				content: "\f1ac";
			}
		}
		.contact-info-title {
			display: block;
			@include fontStyle--4();
			text-transform: uppercase;
			color: $colorText_3;
			margin-bottom: 5px;
			@media (max-width: 767px) {
				display: inline-block;
			}
		}
		.contact-info-subtitle {
			@include fontStyle--6();
			text-transform: none;
			color: $colorText_4;
			@media (max-width: 767px) {
				clear: both;
				display: block;
				padding-left: 40px;
			}
		}
	}
	.desc_contact.contact-title {
		margin-bottom: 20px;
		@include fontStyle--6();
	color: $colorText_3;
		i {
		color: $colorText_3;	
		}
	}
	.contact-form-box{
		background: none;
		border: 1px solid #e8e8e8;
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
		padding: 20px;
		text-align: left;

		fieldset {
			background: none;
			padding: 0;
			>.clearfix {
				text-align: left;
				>div:nth-child(1){
					padding-left: 0;
					@media(max-width: 991px) {
						padding-right: 0;
						margin-bottom: 15px;
					}
				}
				>div:nth-child(2){
					padding-right: 0;
					@media(max-width: 991px) {
						padding-left: 0;
					}
				}
			}
		}
		.page-subheading {
			margin-top: 0;
		}
		div.selector {
			width: 100%!important;
			select.form-control {
				max-width: 100%;
			}
		}
		.form-group {
			margin-bottom: 20px;
		}
		input{
			width: 100%;
			max-width: 100%;
			background: #fff;
		}
		div.uploader {
			height: 34px;
			line-height: 32px;
			input, span {
				height: 34px;
				line-height: 32px;
				border-radius: 4px;
				background-color: #fff;
				font-size: em(14px);
				color: $colorText;
			}
			span.action {
				background-image: none;
				@include btn-style-3();
				height: 34px;
				line-height: 34px !important;
				width: auto;
				margin-left: 5px;
				@media(max-width: 370px) {
					padding: 0 5px;
					min-width: 0;
					
				}
			}
			span.filename {
				float: left;
				width: calc(100% - 133px);
				border-color: #ccc;
				box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
			}
		}
	}
	address {
		.address-title{
		color: $colorText_3;
			margin-bottom: 10px;
			display: block;
			text-align: center;
			text-transform: uppercase;
		}
		+ p {
			overflow: hidden;
		}	
	}
}

/*SITEMAP*/
#left_column {
	ul.tree {
		padding-left: 0;
		li {
			padding: 0;
			margin-left: 0;
			background: none;
			border: none;
		}
	}	
}
#sitemap {
	.sitemap_block {
		li {
			a {
				&:hover {
					font-weight: normal;
					color: $colorText_3;
					text-decoration: underline;	
				}
			}
		}
	} 
}
/*ADDRESSES*/
#addresses {
	.top-breadcrumb {
		padding-bottom: 20px;
	}
	#center_column {
		overflow: hidden;
	}
	.address_update {
		a:nth-child(2) {
			@include btn-style-3();
		}	
	}
	.footer_links {
		li:nth-child(2) {
			a {
				@include btn-style-3();	
			}
		}	
	}	
}
/*IDENTITY*/
#identity {
	.footer_links {
		li:nth-child(2) {
			a {
				@include btn-style-3();	
			}
		}	
	}	
}
/*WISHLIST*/
#module-blockwishlist-mywishlist {
	.footer_links {
		li:nth-child(2) {
			a {
				@include btn-style-3();	
			}
		}	
	}	
}
/*-- Hide overflow line below page-heading --*/
#left_column + #center_column {
	overflow: hidden;
}
