/*=============================== GLOBAL CSS ======================================================*/
html,body {
	font-family: $fontText;
	font-size: $baseFontSize;
	color: $colorText;

	@media (max-width: 1229px) {
		font-size: $baseFontSize*0.9;
	}

	@media (max-width: 991px) {
		font-size: $baseFontSize*0.85;
	}

	@media (max-width: 767px) {
		font-size: $baseFontSize*0.9;
	}
}

body {
	min-width: 0;
}

a {
	-webkit-transition: all .0s;
	-o-transition: all .0s;
	transition: all .0s;
}

img {
	max-width: 100%;
	height: auto;
}

.button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]),
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]),  
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):focus, 
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):active,
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):visited
{
	@include btn-style-2();
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
	-webkit-appearance: none;
	outline: none;
	span {
		border: none;
		padding: 0;
	}

	[class^="icon-"] {
		display: none;
	} 
}

.form-group{
	&.form-error, &.form-ok {
		input {
			background-position: 98% 50%;
		}
	}
}

label {
	margin-bottom: 10px;
	@include fontStyle--6();
	color: lighten($mainColor_2, 30%);
}

input,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"] {
	background: #fff;
	border: 1px solid #ccc !important; 
	height: 34px;
	line-height: 32px;
	font-size: em(14px);
	color: $colorText;
	-webkit-appearance: none;
	border-radius: 4px;
	padding: 0 10px;
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
	&:focus {
		border-color: $colorViewLabel !important;
		outline: none;
		box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
	}
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
input[type=number] {
	-moz-appearance:textfield;	 
}

textarea, textarea.form-control {
	border-color: #ccc !important;
	border-radius: 4px;
	font-family: $fontText;
	padding: 10px !important;
	font-size: em(14px);
	color: $colorText;
	&:focus {
		border-color: $colorViewLabel !important;	
	}
}

fieldset {
	min-width: 0;
}
/*============================================================================================================*/

/*================================================CUSTOME CONTAINER===========================================*/
.columns-container {
	padding-top: 20px;
	#columns {
		padding-top: 0;
	}
}
#index .columns-container {
	//padding-top: 5px;
}

.container {
	@media (min-width: 1230px) {
		max-width: 1200px;
		padding-left: 15px;
		padding-right: 15px;
	}
	@media (max-width: 1229px) and (min-width: 992px) {
		max-width: 970px;
		padding-left: 15px;
		padding-right: 15px;
	}
}

.menu-opened {
	overflow: hidden;

	body.menu-opened {
		position: fixed;
		left: 0;
		top: 0;
	}
}
/*=============================================================================================================*/

/*=============================================BLOCK TITLE=====================================================*/
.title_block h2 {
	@include fontStyle--1();	
color: $colorText_4;
	margin: 0;
	@media (max-width: 767px) {
		font-size: 26px;
	}
}
/*=============================================================================================================*/

/*=============================================MOBILE MENU OPEN================================================*/
.menu-opened #page {
	cursor: url(http://lookbook.arenathemes.com/themes/lookbook/css/../img/toggle_close.png),pointer;
	position: relative;
	&:before {
		content:"";
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background: rgba(0,0,0,0.5);
		z-index: 9999;
	}
}
/*==============================================================================================================*/

/*SELECTOR*/
div.select {
	@include clearfix();
}
div.selector {
	height: 34px;
	background: none;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	
	> span {
		background: none;
		width: 100%!important;
		height: 32px;
		position: relative;
		line-height: 32px;
		color: $colorText;
		font-size: em(14px);
		text-align: left;
		padding-right: 50px;

		&:after {
			content: "\f0d7";
			color: $colorText;
			font-size: 14px;
			font-family: $fontIcon;
			width: 32px;
			height: 32px;
			text-align: center;
			border-left: 1px solid #ccc;
			position: absolute;
			right: 0;
			top: 0;
		}
	}

	select {
		width: 100%;
		height: 32px;
	}

	&.focus {
		border-color: $colorViewLabel !important;
		outline: none;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
	}

	+ span {
		margin-top: 2px;
	}
}

/*RADIO*/
/*div.radio {
	span {
		background: none;
		border: 1px solid #f3f3f3;
		border-radius: 0%;

		&.checked {
			position: relative;
			border-color: $mainColor_1;
			&:after {
				content:"";
				width: 5px;
				height: 5px;
				display: block;
				border-radius: 0%;
				background: $mainColor_1;
				position: absolute;
				left: 3px;
				top: 3px;
			}
		}
	}
}*/

/*CHECKER*/
/*div.checker {
	width: 10px;
	height: 10px;
	position: relative;
	min-height: 0;
	display: inline-block!important;

	+ label { 

		a {
			-webkit-transition-duration: 0s;
			-o-transition-duration: 0s;
			transition-duration: 0s;
		}

		&:hover {

			a {
				color: #ff3837;	
			}
		}
	}

	span {
		background: none;		
		width: 10px;
		height: 10px;
		position: relative;
		z-index: 1;
		top: 0;
		vertical-align: top;
		border: 1px solid #eee;

		&.checked {
			border-color: #ff3837;
			&:before {
				content: "";
				width: 4px;
				height: 4px;
				position: absolute;
				left: 50%;
				top: 50%;
				margin-left: -2px;
				margin-top: -2px;
				background: #ff3837;
			}
		}
	}

	input {
		width: 10px;
		height: 10px;
		min-height: 0;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
	}

	&:hover {
		span {
			border-color: #ff3837;
		}

		+ label {
			color: #ff3837;	
		}
	}
}*/

/*NEW LABEL*/
.new-box {
	min-width: 60px;
	height: 25px;
	padding: 0 15px;
	border-radius: 0;
	background-color: $colorNewLabel;	
	display: block;
	text-align: center;
	line-height: 25px;
	text-transform: uppercase;
	font-family: $fontHeading_2;
	color: $colorText_2;
	position: absolute;
	left: -25px;
	top: -15px;
	z-index: 3;
	transition: left 0.3s ease;
	&:before {
		/*content: "";
		height: 0;
		width: 0;
		position: absolute;
		top: 0;
		left: 0;
		background: linear-gradient(135deg,#fff 45%, #aaa 50%, #ccc 56%, #fff 80%); 
		box-shadow: 1px 1px 1px rgba(0,0,0,0.4);
		transition: width 0.35s, height 0.35s;*/
	}
	&:hover {
		color: $colorText_2;
		background-color:  $colorNewLabel;
		&:before {
			width: 15px;
			height: 15px;
		}
	}	
}

/*PRODUCT SALE LABEL*/
.sale-box {
	min-width: 60px;
	height: 25px;	
	padding: 0 15px;
	border-radius: 0;
	background-color: $colorSaleLabel;	
	text-transform: uppercase;
	font-family: $fontHeading_2;
	display: block;
	text-align: center;
	line-height: 25px;
	color: $colorText_2;
	position: absolute;
	left: -25px;
	top: 10px;
	z-index: 3;
	transition: left 0.3s ease;
	transition-delay: 0.1s;
	&:before {
		/*content: "";
		height: 0;
		width: 0;
		position: absolute;
		top: 0;
		right: 0;
		background: linear-gradient(225deg,#fff 45%, #aaa 50%, #ccc 56%, #fff 80%);
		box-shadow: -1px 1px 1px rgba(0,0,0,0.4);
		transition: width 0.35s, height 0.35s;*/
	}
	&:hover {
		background-color: $colorSaleLabel;
		color: $colorText_2;
		&:before {
			width: 15px;
			height: 15px;
		}
	}
}

/*PRODUCT NAME */
.product-name {
	color: $colorText_3;
	line-height: 1;
	text-transform: uppercase;
	&:hover{
		color: $colorText_4 !important;
		text-decoration: underline;
	}
	a {
		text-transform: uppercase;
		line-height: inherit; 
		display: block;
		color: inherit;
	}
}

/*PRODUCT PRICE*/
.price.product-price, .old-price.product-price {
	@include fontStyle--3();
color: $colorText_4;
	margin: 0;
	line-height: 1;
}

.old-price.product-price {
color: $colorText_4;
}

/*PRODUCT STOCK STATUS*/
.stock-availability {
	border: none;
	display: inline-block;
	color: $colorText_2;
	background: $mainColor_1;
	border-radius: 4px;
	text-transform: uppercase;
	font-family: $fontHeading;
	font-weight: bold; 
	font-size: em(10.5px);
	padding: 3px 6px 2px;
}
.availability {
	span {
		background-color: $mainColor_1;
		border: none;
		padding: 5px 10px;
		color: #fff;
		border-radius: 3px;
	}
}

/*PRODUCT REDUCTION STATUS*/
.product-flags {
	.discount {
		color: #ff3837;
		font-weight: bold;
	}
}

/*PRICE REDUCE PERCEN*/
.price-percent-reduction {
	@extend .new-box;
	background: #ff4444;	
	border: none;
	@include fontStyle--4();
	font-weight: bold;
	&:hover {
		background: #ff4444;				
	}
}

/*TIME COUNTER*/
.clock-block { 
	@include clearfix();
	@media (max-width: 991px) and (min-width: 768px) {
		position: absolute;
		left: 100%;
		bottom: 50px;
		width: 100%;
	}
	.timer {
		@include clearfix();
	}
	.clock { 
		text-align: center;
	} 
	
	li {
		display: inline-block;
		width: 50px;
		height: 50px;
		background: #f3f3f3;   
		font-size: 0;
		border: 2px solid $mainColor_2; 
		margin: 0 22px; 
		text-transform: uppercase;
		padding-top: 10px; 
		position: relative; 
		@media (max-width: 991px) and (min-width: 768px) {
			margin: 0 10px;
		}
		@media (max-width: 767px) {
			margin: 0 7px;
			width: 40px;
			height: 40px;
			padding-top: 5px;
		}
		&:after {
			content: "";
			display: block;
			width: 4px;
			height: 24px;
			position: absolute; 
			top: 13px;
			right: -26px;
			background: url('../images/dot-line-2.png') no-repeat center;
			@media (max-width: 991px) and (min-width: 768px) {
				right: -14px;
			}
			@media (max-width: 767px) {
				right: -11px;
				top: 7px;
			}
		}
		&:last-child:after {
			display: none;
		}
		span {
			@include fontStyle--3();
		color: $colorText_4;
			font-weight: bold;
			@media (max-width: 767px) {
				font-size: em(18px);
			}
		}
	}
}

/*PRODUCT ITEM*/
.ajax_block_product {
	padding: 0 15px;
	.product-container {
		border: 1px solid #e8e8e8;
		padding: 15px 15px 10px;
		position: relative;
		overflow: hidden;
		@media (max-width: 480px) {
			padding: 10px 5px 10px;
			.new-box {
				left: -5px !important;
				top: -10px !important;
			}
			.sale-box {
				left: -5px !important;
				top: 15px !important;
			}
		}
		/*Functional buttons*/
		.functional-buttons {
			display: inline-block;
			overflow: hidden;
			padding-top: 10px;
			.quick-view-wrapper-mobile,
			.lnk_view {
				display: none !important;
			}

			.addToWishlist, .add_to_compare,
			.quick-view {
				@include btn-functional-general();
				float: left;
			}

			.ajax_add_to_cart_button {
				@include btn-cart();
			}
			.addToWishlist {
				@include btn-functional-content("\f004"); 
				&.checked {
					@include btn-functional-content("\f184");
					background: $colorViewLabel;	
				}	
			}
			.add_to_compare {
				@include btn-functional-content("\f080"); 
				&.checked {
					@include btn-functional-content("\f200");
					background: $colorViewLabel;	
				}		
			}
			.quick-view {
				width: 80px;
				height: 80px;
				background: $colorViewLabel;
				display: block;
				position: absolute;
				top: -80px;
				right: -25px;
				transform: rotate(0deg);
				transform-origin: 0 100%;
				transition: all 0.2s ease-out;
				&:hover {
					background: $colorViewLabel;
				}
				&:after {
					content: "\f06e";
					font-family: $fontIcon;
					display: block;
					font-size: em(14px);
					line-height: 1;
					padding-top: 15px;
					padding-right: 13px;
					transform: rotate(-45deg);
				} 
				@media (max-width: 767px) {
					display: none !important;
				}
							
			}

			.ajax_add_to_cart_button {
				float: left;
				margin: 0!important;
				@media (max-width: 1229px) {
					margin-bottom: 5px !important;
				}
				@media (max-width: 991px) and (min-width: 768px) {
					span {
						padding: 0 13px;
					}
				}
			}
			
			.wishlist, .compare,
			.quick-view {
				margin-left: 1px !important;
				float: left;
				@media(max-width: 1229px) and (min-width: 768px) {
					margin-left: 1px !important;
				}
			}

		}
		/*left block*/
		.left-block {
			position: relative;
			width: 100%;
			.product_img_link {
				@include clearfix();
				display: block;
				&:before {
					content:"";
					display: none;
					visibility: hidden;
					opacity: 0;
					transition: all 0.35s ease-out;
					background: rgba(255,255,255,0.7);
					z-index: 2;
					position: absolute;
					left: -10px;
					top: -10px;
					right: -10px;
					bottom: -10px;
				}
				.img-responsive {
					transition: all .35s;
					transform: scale(1,1);
					max-width: 100%;
					@media (max-width: 767px) {
						width: 100%;
					}
				}
				.image-product-hover {
					max-width: 100%;
					position: absolute;
					left: 0;
					top: 0;
					opacity: 0;
					transition: all 0s;
					transform: scale(1,1);
					transition-delay: 0s;
					@media (max-width: 767px) {
						width: 100%;
					}
				}
			}
			.product-image-container {
				padding: 0px;
			}
			.button-container {
				position: absolute;
				top: 50%;
				left: 50%;
				z-index: 3;
				width: 100%;
				text-align: center;
				transform: translate(-50%, -50%);
				.ajax_add_to_cart_button,
				.addToWishlist, .add_to_compare,
				.quick-view {
					-webkit-animation-duration: .5s;
				  	animation-duration: .5s;
				  	-webkit-animation-fill-mode: backwards;
				  	animation-fill-mode: backwards; 
				  	-webkit-animation-name: zoomIn;
    				animation-name: zoomIn; 
    				display: none;
				}
				.ajax_add_to_cart_button {
					animation-delay: 0.2s;
				}
				.addToWishlist {
					animation-delay: 0.3s;
				}
				.add_to_compare {
					animation-delay: 0.4s;
				}
				.quick-view
				{
					animation-delay: 0.5s;
				}
			}	
		}
		/*right block*/
		.right-block {
			text-align: left;
			padding: 20px 0;
			@include clearfix();
			h5 {
				margin: 0;
			}
			.button-container {
				line-height: 0;
			}	
			.product-name {
				font-size: em(18px);
			color: $colorText_3;
				padding: 0px;
				margin: 10px 0;
				display: block;
				&:hover {
					text-decoration: underline;
				color: $colorText_4;
				}
			}
			.product-desc {
				color: $colorText;
				padding: 0;
				-ms-text-overflow: ellipsis;
				text-overflow: ellipsis;
				white-space: normal;
				overflow: hidden;
				max-height: 36px;
				margin: 5px 0;

			}
			.availability,
			.product-flags {
				display: none !important;
			}
			.content_price,
			.reviews-container
			{
				float: left;
				width: 100%;
				height: auto;
				margin: 0;
			}
			.content_price {
				background-color: none;
				padding: 10px 0;
				.price {
					padding-right: 5px;
				}
				.old-price {
					color: lighten($mainColor_2, 30%);
					font-size: em(18px);
				}
			}
			.reviews-container {
				background-color: none;
				padding-bottom: 10px;
				.comments_note {
					padding: 0;
					height: auto;
					.star_content {
						height: 100%;
						padding-top: 0px;
					}
				}
			}
			.price-percent-reduction {
				display: none !important;
				top: 55px;
				@media (max-width: 767px) {
					top: 40px;
				}
			}
		}
		&:hover {
			.left-block {
				.product_img_link {
					.img-responsive {
						transform: scale(1,1);
						opacity: 0;
					}
					.image-product-hover {
						transform: scale(1,1);
						opacity: 1;
						transition: all .35s;
						
					}
					&:before {
						visibility: visible;
						opacity: 1;
					}
				}
				.button-container {
					.ajax_add_to_cart_button,
					.addToWishlist, .add_to_compare,
					.quick-view {
						display: block;
					}
				}
				.new-box, .sale-box {
					left: -15px;
				}
			}
			.right-block {
				.functional-buttons {
					.quick-view {
						transform: rotate(45deg);
					}
				}
			}
		}	
	}	
	&.no-review {
		.right-block {
			.reviews-container {
				display: none; 
			}
			.content_price {
				width: 100%;
				background: transparent;
				.product-price {
					position: relative;
					top: -10px;
				}
			}		
		}
		
	}
}

/*SCROLL TO TOP BUTTON*/
#scroll-to-top {
	position: fixed;
	right: 10px;
	bottom: 100px;
	border-radius: 0;
	width: 40px;
	height: 40px;
	line-height: 40px !important;
	z-index: 200;
	font-size: 0;
	text-align: center;
	color: #eee; 
	background: $colorBtn_1;
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
	cursor: pointer;
	&:after {
		border-radius: 0;
	}
	&:before {
		content: "\f062";
		font-family: $fontIcon;		
		font-size: 15px;
	}

	&:hover {
		color: #fff;
		@include bob-hover();	
		&:before {
			
		}
	}
}

/*OWL SLIDER CONTROL*/
.slideNav {
	border-radius: 0px;
	margin: 0 5px!important;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	color: $colorText_2;
	font-size: 0;
	border: none;
	background: none;
	line-height: 1;


	&:before {
		font-family: $fontIcon;
		text-align: center;
		font-size: 24px;
		font-weight: normal;	
		line-height: 1;
		display: block;	
		color: inherit;
		width: 100%;
		height: 100%; 
		text-align: center;
		padding: 0;
	}

	&:hover {
		color: $colorText_3 !important;
	}	
}
.slidePrev {
	&:before {
		content: "\f053";				
	}	
}
.slideNext {
	&:before {
		content: "\f054";
	}	
}
.owl-controls {

	.owl-nav {

		.owl-prev, .owl-next {
			@extend .slideNav;	
		}

		.owl-prev {
			@extend .slidePrev;
		}

		.owl-next {
			@extend .slideNext;
		}	
	}
}

/*BLOCK TAG*/
.tags_block {
	a {
		border: 1px solid transparent;
		border-radius: 3px;
		padding: 0 15px;
		background-color: #f3f3f3;
		float: left;
		margin-right: 10px;
		margin-bottom: 12px;
		min-height: 30px;
		padding-top: 5px; 
		&:hover {
			background: $mainColor_1;
			color: #fff !important;
		}
	}
}

/*BUTTON COMPARE SUBMIT ON CATEGORY PAGE*/
.button.button-medium.bt_compare {
	opacity: 1;
	cursor: pointer;
	i {
		display: none;
	}
	@include btn-style-3();
	&:hover {
		opacity: 1;
	}
	&:before {
		content: "\f080";
		display: inline-block;
		padding-right: 5px;
		font-family: $fontIcon;
		font-size: 14px;
		color: $colorText_2;	
	}
}

/*REVIEW STAR*/
.reviews-container {
	
}
.comments_note {
	font-size: 0;
	text-align: left;
	.nb-comments {
		display: none;
	}
}

.star_content {
	line-height: 1;

	.star {
		float: none;
		display: inline-block; 
		margin: 0 2px;

		&.star_on {
			display: inline-block;
		}

		&:after {
		color: $colorText_3!important; 
		}
	}
}

/*PAGE HEADING*/
.page-heading {
	line-height: 1.2;
	padding-bottom: 20px;
	margin-bottom: 20px !important;
	@include fontStyle--2();
	text-transform: uppercase;
	font-weight: normal;
	text-align: center;
color: $colorText_4;
	position: relative;
	@media (max-width: 767px) {
		font-size: 26px;
	}
	/*&:before {
		content: "";
		height: 1px;
		display: block;
		position: absolute;
		left: 50%;
		bottom: 0;
		background-color: #f3f3f3;
		width: 50vw;
		transform: translate(-100%, 0);
	}
	&:after {
		content: "";
		display: block;
		background-color: #fff;
		width: 10px;
		height: 10px;
		border: 2px solid #f3f3f3;
		border-radius: 10px;
		position: absolute;
		bottom: -5px;
		left: 50%;
		margin-left: -5px;
	}*/

	.lighter {
	color: $colorText_3;
		font-weight: normal;
	}

	span.heading-counter {
		@include fontStyle--5();
	color: $colorText_3;
		font-weight: normal;
		float: right;
		position: absolute;
		right: 0;
		bottom: 0;
	}
}

/*PAGE SUB-HEADING*/
.page-subheading {
	@include fontStyle--3();
	font-weight: normal;
	border-bottom: 1px solid lighten($mainColor_2,40%);
	margin: 0 0 20px;
color: $colorText_4;
	position: relative;
	display: inline-block;
	line-height: 1;
	padding: 5px 0 15px 0;
	width: 100%; 
	/*&:before {
		content:'';
		width: 20px;
		height: 20px;
		position: absolute;
		right: 0px;
		top: 0px;
		border-top: 1px solid $mainColor_1;
		border-right: 1px solid $mainColor_1;
	}
	&:after {
		content:'';
		width: 20px;
		height: 20px;
		position: absolute;
		left: 0px;
		bottom: 0px;
		border-bottom: 1px solid $mainColor_1;
		border-left: 1px solid $mainColor_1;
	}*/
}

/*HISTORY PRICE*/
.history_price {
	.price {
		color: #ff3837;
		font-size: 16px;
		font-weight: bold;	
	}
	
}

/*TOP BANNER*/
#header {
	#banner_block_popup {
		margin-bottom: 0;
		text-align: center;
		padding: 10px 15px;
		position: relative;
		font-family: $fontHeading;
		.text_content {
			color: $colorText_2;
			padding: 0 30px;
			text-transform: uppercase;
			p {
				margin-bottom: 0;
			}
		}
		.ads-bar-link {
			display: inline-block;
			color: $colorText_2;
			font-weight: bold;
			padding-left: 5px;
			text-decoration: underline;
		}
		.close {
			font-size: 0;
			position: absolute;
			border: none;
			position: absolute;
			right: 15px;
			top: 4px;
			width: 30px;
			height: 30px;
			line-height: 30px;
			background: url('../images/close.png') no-repeat center;
			transform: rotate(0deg);
			transition: transform 0.5s ease-out;
			&:after {
				display: none;
			}
			span {
				padding: 0;
				border: none;
				display: none;
				
			}
			&:hover {
				//transform: rotate(360deg);	
			}
		}
	}
	
}

/*COOKIE LAW*/
#cookie-popup {
	background: $mainColor_1; 
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10000;
	#cookie_block_popup {
		margin-bottom: 0;
		padding: 20px 0 10px;
		@media(max-width: 767px) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	.text_content {
		width: 60%;
		float: left;
		@include fontStyle--6();
		color: #fff;
		.hi-cookie-head	{
			text-transform: uppercase;
		}
		@media (max-width: 370px) {
			width: 100%;
		}
	}
	.button-container {
		width: 40%;
		float: right;
		text-align: right;
		padding-top: 5px;
		@media (max-width: 370px) {
			width: 100%;
			text-align: left;
		}
	}
	.btn {
		@include btn-style-3();
		margin: 0 5px 10px;
		text-shadow: none;
		cursor: pointer;
		display: inline-block;
		span {
			background: none;
			border: none;
			padding: 0;
		}
		&:hover {
			background: lighten($colorBtn_2, 20%);
		}
	}
}

/*STICKY MENU*/
.menu-sticky {
	&.bc-stickymenu-fixed {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		z-index: 100;
		/*-webkit-box-shadow: 0 3px 5px 0px #f3f3f3;
		box-shadow: 0 3px 5px 0px #f3f3f3;*/
	}	
}