$menuHeight: 50px;
/* CSS FOR MEGAMENU */
.link-with--icon {
	&:before {
		content: "\f054";
		display: inline-block;
		font-family: $fontIcon;
		font-size: em(12px);
	color: $colorText_3;
		margin-right: 5px;
		position: relative;
	}
}
#header > .mega-menu {
	background: $mainColor_1;
	
	
	.mega-menu {
		@media (max-width: 767px) {
			display: none;
		}
	}
}
.bc-menu-container { 
		
	.title-menu-mobile {
		display: none;
		width: 46px;
		height: 46px;
		border: 1px solid $mainColor_1;
		border-radius: 3px;
		background: $mainColor_1;
		font-size: 0;
		text-align: center;
		line-height: 46px;
		color: #fff;
		cursor: pointer;
		&:hover {
			
		}
		&:before {
			content: "\f0c9";
			font-family: $fontIcon;
			font-size: 18px;
		}
		@media (max-width: 767px) {
			display: inline-block;
			position: absolute;
			right: 15px;
			top: 0;
			margin-top: -46px;
		}
	}
		
	.menu-content {
		height: $menuHeight;
		margin-bottom: 0;

		img {
			width: 100%;
		}

		>li {
			display: inline-block;
			float: left;
			color: #fff;
			text-transform: uppercase;
			height: 100%;
						
			-webkit-transition: all .5s;
			-o-transition: all .5s;
			transition: all .5s;
			position: relative;

			a {	
				display: block;
				@include fontStyle--5();
			color: $colorText_4;
				font-weight: normal;
				text-transform: uppercase;
			}

			> a {
				height: 100%;
				line-height: $menuHeight;
				padding: 0 15px;
				font-size: em(14px);
				font-family: $fontHeading_2;
				font-weight: normal;
				color: $colorText_2;
				@media (max-width: 991px) {
					padding: 0 10px;
				}
			} 

			&:hover {
				background: $mainColor_2;
				> a {
					color: $colorText_2;
					&:after {
						color: #fff;
					}
				}
			}
		}
	}
}
.bc-menu-container  {
/*Share properties between horizontal menu and vertical menu*/
	.dropdown {
		display: none;
		padding-top: 20px;
		padding-bottom: 20px; 
		background-color: #fff;
		border: 1px solid #f3f3f3;
		@media (max-width: 991px) {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
	.icon-drop-mobile {
		&:before {
			content: "\f054"; 
			color: $colorText_3;
			display: block;
			font-size: em(12px);
			font-family: $fontIcon;
		}
	}
	.content-drop {
		@include clearfix();
		padding-left: 0;
		padding-right: 0;
	}
	.menu-column {
		padding: 0 30px;
		@media (max-width: 991px) {
			padding: 0 15px;
		}
	}
	.column-item {
		@include clearfix();
	color: $colorText_4;
		li:hover {

			> .icon-drop-mobile {
			color: $colorText_3;
				-webkit-transition: all .3s;
				-o-transition: all .3s;
				transition: all .3s;
			}

			> .column-item  {
				visibility: visible;
				opacity: 1;
				-webkit-transition: all .3s;
				-o-transition: all .3s;
				transition: all .3s;
			}
		}

		a {
			padding: 10px 0;
			line-height: normal;
			text-transform: none;
			font-family: $fontText;

			&:hover {
			color: $colorText_3;
				text-decoration: underline;
			}
		}

		h3 {
			
			margin-top: 0;
			margin-bottom: 15px;
		}

		.parent {
			position: relative;

			.icon-drop-mobile {
				position: absolute;
				right: 0;
				top: 12px;
				&:after {
					content:"";
					display: block;
					position: absolute;
					left: -10px;
					top: -10px;
					bottom: -10px;
					right: -10px;
					z-index: 2;
				}
			}

			.column-item {
				position: absolute;
				left: calc(100% + 30px);
				top: 0;
				min-width: 195px;
				background: #fff;
				padding: 0 25px;
				padding-left: 25%;
				visibility: hidden;
				opacity: 0;
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
				&:before {
					content:'';
					display: block;
					width: 35px;
					height: 100%;
					position: absolute;
					left: -35px;
					top: 0;
				}
				@media (max-width: 991px) {
					min-width: 140px;
					left: calc(100% + 15px);
					padding: 0 15px;
					&:before {
						width: 20px;
						left: -20px;
					}
				}
			}
		}
	}
	.menu-item {
		&:hover {
			.dropdown {
				display: block;
			}
		}
		h3 {
			padding-bottom: 18px;
			margin-bottom: 20px;
			border-bottom: 1px solid lighten($mainColor_2, 20%);
			text-transform: uppercase;
			font-size: em(18px);
			font-weight: normal;
		color: $colorText_4;
			&:before {
				content: '';
				display: none;
				width: 5px;
				height: 5px;
				background: $mainColor_1;
				margin-right: 7px;
				position: relative;
				top: -3px;
			}
		}
		.ajax_block_product {
			@include clearfix();
			padding: 0;
			.product-container {
				@include clearfix();
				.left-block {
					border: none;
					.product_img_link {
						padding: 0;
					}
				}
				.right-block {
					.product-name {
						padding: 0;
						margin: 0;
						a {
							color:inherit;
							font-size: em(18px);
							margin: 10px 0;
							padding: 0;
						}	
					}
				}
			}	
		}
		&.menu-v1 {
			.dropdown {
				background-image: url('../images/mega_menu_bg_img_1.jpg');
				background-repeat: no-repeat;
				background-position: right center;
				background-size: auto 120%;
				@media (max-width: 991px) {
					background-size: auto 100%;
				}
			}
			.content-drop {
				overflow: hidden;
			}
			.column-item {
				border-right: 1px solid #eee;
				padding-bottom: 1000px;
				margin-bottom: -1000px;
				padding-right: 30px;

				a {
					@extend .link-with--icon;
				}
			}
			.menu-column {
				padding-right: 0;
				&:last-child, &:nth-child(3) {
					.column-item {
						border: none;
					}	
				}
			}	
		}
		&.menu-v2 {
			.level-2 > a {
				@extend .link-with--icon;
			}
			.parent {
				.icon-drop-mobile { 
					display: none;
				}
				.column-item {
					position: relative;
					left: 0;
					top: 0;
					visibility: visible;
					opacity: 1;
					padding-left: 0;
					min-width: 0;  
					white-space: nowrap;	
					box-shadow: none;
					li {
						a {
							padding: 5px 0;
						color: $colorText_4;
							font-size: em(14px);
							padding-left: 15px;
							@media (max-width: 991px) {
								padding-left: 0;
							}
							&:before {
								font-size: em(12px);
							}
							&:hover{
							color: $colorText_3;
								padding-left: 15px;
								@media (max-width: 991px) {
									padding-left: 0;
								}
							}

						}
					}
				}	
			}	
		}
		&.menu-v3 {
			img {
				margin-bottom: 20px;
			}	
		}
		&.menu-v4 {
			.dropdown {
				background-image: url('../images/mega_menu_bg_img_2.jpg');
				background-repeat: no-repeat;
				background-position: right bottom;
				-webkit-background-size: 30%;
				background-size: 30%;
			}
			.content-drop {
				overflow: hidden;
			}
			.column-item {
				border-right: 1px solid #eee;
				padding-bottom: 1000px;
				margin-bottom: -1000px;
				padding-right: 30px;
			}
			.menu-column {
				padding-right: 0;
				&:last-child, &:nth-child(3) {
					.column-item {
						border: none;
					}	
				}
			}
			.ajax_block_product {
				.product-container {
					border: none;
					padding: 0;
				}
				.left-block {
					width: 64px;
					margin-right: 15px;
					float: left;

				}
				.right-block {
					float: left;
					padding-top: 0;
					width: calc(100% - 79%);
					.product-name {
						text-align: left;
						margin-top: 0;
						padding: 0;
						a {
							@include fontStyle--5();
						color: $colorText_4;
							text-transform: none;
							padding: 0;
							margin: 10px 0 7px;
						}
					}
					.content_price {
						text-align: left;
						padding-top: 0;
						.price {
							@include fontStyle--4();
						color: $colorText_3;
						}
					}
				}
			}
		}
	}
/*Horizontal Menu unique props*/	
	&#megamenu_top {		
		/*grid*/
		.col-sm-12 {
			width: 1170px;
			@media(max-width: 1229px)
			{
				width: 940px;
			}
			@media(max-width: 991px)
			{
				width: 720px;
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-11 {
			width: menu-grid(1170,11,12);
			@media(max-width: 1229px)
			{
				width: menu-grid(940,11,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(720,11,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-10 {
			width: menu-grid(1170,10,12);
			@media(max-width: 1229px)
			{
				width: menu-grid(940,10,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(720,10,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-9 {
			width: menu-grid(1170,9,12);
			@media(max-width: 1229px)
			{
				width: menu-grid(940,9,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(720,9,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-8 {
			width: menu-grid(1170,8,12);
			@media(max-width: 1229px)
			{
				width: menu-grid(940,8,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(720,8,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-7 {
			width: menu-grid(1170,7,12);
			@media(max-width: 1229px)
			{
				width: menu-grid(940,7,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(720,7,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-6 {
			width: menu-grid(1170,6,12);
			@media(max-width: 1229px)
			{
				width: menu-grid(940,6,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(720,6,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-5 {
			width: menu-grid(1170,5,12);
			@media(max-width: 1229px)
			{
				width: menu-grid(940,5,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(720,5,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-4 {
			width: menu-grid(1170,4,12);
			@media(max-width: 1229px)
			{
				width: menu-grid(940,4,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(720,4,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-3 {
			width: menu-grid(1170,3,12);
			@media(max-width: 1229px)
			{
				width: menu-grid(940,3,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(720,3,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-2 {
			width: menu-grid(1170,2,12);
			@media(max-width: 1229px)
			{
				width: menu-grid(940,2,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(720,2,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-1 {
			width: menu-grid(1170,1,12);
			@media(max-width: 1229px)
			{
				width: menu-grid(940,1,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(720,1,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}

		.menu-item {
			> .icon-drop-mobile {
				display: none;
			}	
		}
				
		.dropdown {
			position: absolute;
			top: $menuHeight;
			width: auto;
			left: 0;
			z-index: 100;
			box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
		}
		
	}
/*Vertical menu unique props*/
	&#menu_vertical {
		padding-top: 20px;
		padding-left: 0;
		position: relative;
		@media (max-width: 767px) {
			height: 0px;
			margin-top: -17px;
			z-index: 1000;
			#bc_menu_collapse_menu_vertical {
				display: none;
			}
			.title-menu-mobile {
				left: 0;
				top: -53px;
			}	
		}
		/*grid*/
		.col-sm-12 {
			width: 893px;
			@media(max-width: 1229px)
			{
				width: 719px;
			}
			@media(max-width: 991px)
			{
				width: 555px;
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-11 {
			width: menu-grid(893,11,12);
			@media(max-width: 1229px)
			{
				width: menu-grid(719,11,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(555,11,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-10 {
			width: menu-grid(893,10,12);
			@media(max-width: 1229px)
			{
				width: menu-grid(719,10,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(555,10,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-9 {
			width: menu-grid(893,9,12);
			@media(max-width: 1229px)
			{
				width: menu-grid(719,9,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(555,9,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-8 {
			width: menu-grid(893,8,12);
			@media(max-width: 1229px)
			{
				width: menu-grid(719,8,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(555,8,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-7 {
			width: menu-grid(893,7,12);
			@media(max-width: 1229px)
			{
				width: menu-grid(719,7,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(555,7,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-6 {
			width: menu-grid(893,6,12);
			@media(max-width: 1229px)
			{
				width: menu-grid(719,6,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(555,6,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-5 {
			width: menu-grid(893,5,12);
			@media(max-width: 1229px)
			{
				width: menu-grid(719,5,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(555,5,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-4 {
			width: menu-grid(893,4,12);
			@media(max-width: 1229px)
			{
				width: menu-grid(719,4,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(555,4,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-3 {
			width: menu-grid(893,3,12);
			@media(max-width: 1229px)
			{
				width: menu-grid(719,3,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(555,3,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-2 {
			width: menu-grid(893,2,12);
			@media(max-width: 1229px)
			{
				width: menu-grid(719,2,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(555,2,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-1 {
			width: menu-grid(893,1,12);
			@media(max-width: 1229px)
			{
				width: menu-grid(719,1,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(555,1,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.container {
			padding: 0;
		}
		.title-menu {
			min-height: 55px;
			background: $mainColor_1;	
			font-size: em(14px);
			color: #fff;
			text-transform: uppercase;
			font-family: $fontHeading;
			padding: 19px 20px 18px;
			position: relative;
			&:after {
				content: "\f0c9";
				display: block;
				position: absolute;
				right: 18px;
				top: 20px;
				font-family: $fontIcon;
				color: #fff;
				font-size: 18px;
			}
			@media (max-width: 991px) {
				min-height: 45px;
				padding: 14px 20px 13px;
				&:after{
					top: 15px;
				}
			}
		}
		.menu-content {
			border: 1px solid #f3f3f3;
			height: auto;
			@include clearfix();
			.menu-item {
				padding: 17px 0px 17px 0px;
				position: relative;
				border: none;
				border-bottom: 1px solid #f3f3f3;
				width: 100%;
				font-size: em(14px);
				min-height: 54px;
				@media (max-width: 991px) {
					min-height: 44px;
					padding: 12px 0px 12px 0px;
				}
				&:last-child{
					border-bottom: none;
				}
				&:hover {
					.dropdown {
						display: block;
					}
					>.icon-drop-mobile {
					color: $colorText_3;
					}
					
				}
				>a {
					line-height: normal;
					padding: 0 30px 0 20px;
					@media (max-width: 991px) {
						padding: 0 20px 0 10px;
					}
					font-size: inherit;
				}
				> .icon-drop-mobile{
					position: absolute;
					right: 0;
					top: 0;
					width: 25px;
					height: 100%;
					padding-top: 20px;
				color: $colorText_4;
					@media (max-width: 991px) {
						padding-top: 13px;
						width: 15px;
					}
				}
			}
		}

		.dropdown {
			position: absolute;
			left: 100%;
			top: 0;
			padding-top: 20px;
			padding-bottom: 20px;
			z-index: 100;
			background-color: #fff;
			margin-left: 0 !important;
			box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.4);
		}

			
	}
}

/*Mobile menu*/
.mobile-version.visible-xs.visible-sm {
	/*temporary hide mobile menu on tablet layout*/
	@media (min-width: 768px) {
		display: none !important;
	}
}

#menu_mobile {
	width: 280px;
	height: 100%;
	background: $mainColor_2;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 10005;
	overflow: auto;
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
	-webkit-transform: translate(-280px,0);
	-ms-transform: translate(-280px,0);
	-o-transform: translate(-280px,0);
	transform: translate(-280px,0);
	&.opened {
		-webkit-transform: translate(0,0);
		-ms-transform: translate(0,0);
		-o-transform: translate(0,0);
		transform: translate(0,0);
	}
	.container {
		padding-left: 0;
		padding-right: 0;
	}
	.menu-content {
		height: auto;
		@include clearfix();
		padding: 0 15px;
		.dropdown {
			background: transparent;
			color: $colorText_2;
		}
		> li {
			display: block;
			width: 100%;
			border-bottom: 1px dotted $colorText_2;
			border-left: none;
			border-right: none;
			.dropdown {
				clear: both;
				padding-top: 0;
				border: none;
				padding-bottom: 20px;
			}
			.menu-column{
				padding: 0;
			}
			.column-item {
				background: transparent;
				position: relative;
				left: 0;
				top: 0;
				opacity: 1;
				padding: 0;
				visibility: visible;
				min-width: 0;
				padding-left: 10px;
				-webkit-transition-duration: 0s;
				-o-transition-duration: 0s;
				transition-duration: 0s;
				box-shadow: none;
				color: $colorText_2;
				li {
					a {
						text-transform: none;
					}
					.column-item {
						display: none;
					}	
				}
			}
			.icon-drop-mobile {
				cursor: pointer;
				position: absolute;
				right: 0;
				top: 6px;
				width: 30px;
				height: 30px;
				line-height: 30px;
				text-align: right;
				z-index: 10;
				&:before {
					content: "\f13a";
					font-family: $fontIcon;
					font-size: 13px;
					color: $colorText_2;
				}
				&.opened {
					&:before {
						content: "\f139";
						color: $colorText_3;  
					}	
				}
			}
			a {
				color: inherit;
			}
			> a {
				line-height: 40px;
				display: block;
				float: left;
				width: 100%;
				padding: 0;
			}
			&:last-child {
				border: none;
			}
			&:hover {
				> a {
					color: $colorText_3;
				}
				.dropdown {
					display: none;
				}
			}
		}
	}
	.title-menu {
		background: $mainColor_1;
		text-align: left;
		border-bottom: none;
		span {
			font-size: em(20px);
			font-weight: bold;
			color: #fff;
			text-transform: uppercase;
			padding: 10px 15px;
			display: block;
		}
	}
	nav {
		border-top: none;
		margin-left: 0;
		margin-right: 0;
	}
}