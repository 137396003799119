/*==============================CSS FOR CATEGORY PAGE==============================*/
/* BREADCRUMB */
.top-breadcrumb {
	background: none;	
	background-size: cover;
	@media (max-width: 991px) {
		.container {
			padding-left: 15px;
			padding-right: 15px;	
		}
		
	}

	.breadcrumb-title {
		@include clearfix(); 
		padding: 10px 18px 3px;
		border: 1px solid #e8e8e8;

		a[name="back"] {
			display: none;
		}

		h1 {
			display: inline-block;
			float: left;
			width: 100%;
			text-align: center;
			color: #fff;
			font-weight: bold;
			margin: 0;
			line-height: 1;
			@media (max-width: 991px){
				width: 100%;
				text-align: center;
			}
		}

		.breadcrumb {
			display: inline-block;
			float: none;
			padding: 0;
			width: 100%;
			margin: 0;
			border: none;
			border-radius: 0;
			background-color: transparent;
			text-shadow: none;
			font-size: em(16px);
			font-weight: normal;
			text-transform: uppercase;
			color: $colorText;
			text-align: left;
			font-family: $fontHeading;
			font-size: em(13px);

			>span {
				a {
					padding-right: 5px;
				}
			}

			.navigation-pipe {
				width: auto;
				line-height: 1;
				display: inline-block;
				position: relative;
				top: 4px;
				vertical-align: top;
				font-size: 0;
				text-align: center;
				text-indent: 0;
				padding-right: 5px; 

				&:before {
					content: "\00BB";
					font-family: $fontIcon;
					display: inline-block;
					font-size: em(13px);
					color: $colorText;
				}
			}

			.navigation_page {
				display: inline;
				margin-left: 0px;
				span {
					display: inline-block;
				}
				a {
					margin-left: 0;
					padding-right: 5px;
				}
				.navigation-pipe {
					padding-right: 10px;
				}
			}

			a {
				background: transparent;
				width: auto;
				font-weight: normal;
				margin-right: 0;
				padding: 0;
				display: inline-block;
			color: $colorText_4;
				&:before, &:after {
					display: none;
				}
				&:hover {
					text-decoration: underline;
				}
				&.home {
					font-size: 0;
					width: 20px;
					height: 20px;
					text-align: center;
					margin-left: 0;
					&:before {
						content: "\f015";
						display: block;
						font-family: $fontIcon;
						font-size: em(13px);
					color: $colorText_4;	
						position: relative;
						left: 0; top: 0;
						-webkit-transform: rotate(0deg);
						-ms-transform: rotate(0deg);
						-o-transform: rotate(0deg);
						transform: rotate(0deg);
						-webkit-transition: all .35s;
						-o-transition: all .35s;
						transition: all .35s;
						text-indent: 0;
						margin-right: 0;
					}
					&:hover:before {
					color: $colorText_3;
					}
					+.navigation-pipe {
						padding-left: 0px;
					}
				}
			}

			> a, > span {
				vertical-align: top;
				display: inline-block;
				color: inherit;
				float: left;
			}
		}
	}
}

/* CATEGORY BLOCK LEFT */
#left_column {
	font-family: $fontHeading;
	a {
	color: $colorText_4;
	}
	.bc-banner-left {
		margin-bottom: 30px;
		@media (max-width: 767px) {
			display: none;
		}
		img {
			width: 100%;
			display: block;
		}
	} 
	.content_left {
		padding: 0px 15px 30;
		@media (max-width: 767px) {
			border: none;
			background: none;
			padding: 20px 0;
		}
	}
	
	/*Checkbox on all section*/
	div.checker {
		top: 1px;
	}

	/*Remove all bottom border of layer filter section*/
	.layered_filter {
		border: none;	
	}

	/*Make price filter visible children when responsive*/
	.layered_price {
		>ul {
			overflow: visible;
			padding: 10px 15px !important;	
		}
	}

	/*CSS for color filter section*/
	.color-group {
		li {
			position: relative;
			.color-option {
				margin-right: 5px;
				position: relative;
				top: 1px;
			}
		}
	}
	/*Css for title block*/
	.title_block {
		background: none;
		border: none;
		@include fontStyle--4();
		text-transform: uppercase;
		color: $colorText_2;
		background: $mainColor_2;
		font-weight: normal;
		padding: 12px 20px;
		line-height: 1;
		position: relative;
		margin-bottom: 10px;
		a {
			color: inherit;
		}
		+.block_content {
			border: 1px solid #ddd;
		}
		&:before {
			/*content: '';
		    display: inline-block;
		    width: 5px;
		    height: 5px;
		    background: $mainColor_1;
		    margin-right: 7px;
		    position: relative;
		    float: left;
		    top: 7px;*/
		} 
		&:after {
			content: "\f13a";
			font-family: $fontIcon;
			display: none;
			position: absolute;
			right: 0;
			top: 0px;
			color: inherit;
			font-size: 1rem;
			height: 40px;
			width: 40px;
			line-height: 40px;
			text-align: right;
			padding: 0 15px;
		}
		&.active {
			&:after {
				content: "\f139";
			}	
		}
		@media (max-width: 767px) {
			cursor: pointer;
			&:after {
				display: block;
				font-size: 13px;
			}
			&:hover, &.active {
				color: $colorText_3;
				a {
					color: $colorText_3;	
				}
			}
		}
	}

	.block {
		clear: both;
		.block_content {
			margin-top: 0;
		}
		/*Block SubTitle*/
		.layered_subtitle  {
			background: none;
			border: none;
			@include fontStyle--4();
			text-transform: uppercase;
			color: $colorText_2;
			background: $mainColor_2;
			font-weight: normal;
			padding: 12px 20px;
			line-height: 1;
			position: relative;
			margin-bottom: 10px;
			width: 100%;
			a {
				color: inherit;
			}
			&:before {
				content: '';
			    display: none;
			    width: 5px;
			    height: 5px;
			    background: $mainColor_1;
			    margin-right: 7px;
			    position: relative;
			    float: left;
			    top: 7px;
			}
			+ ul {
				border: 1px solid #ddd;	
				margin-bottom: 30px;
				padding: 0;
				> li {
					width: 100%;
					padding: 10px 15px !important;
					border-bottom: 1px solid #ddd;
					&:last-child {
						border-bottom: none;
					}
				}
				label {
					margin-bottom: 0;
				}	
			}
		}
		.layered_subtitle_heading + ul {
			border: 1px solid #ddd;	
			margin-bottom: 30px;	
			padding: 0;
			label {
				margin-bottom: 0;
			}
			> li {
				width: 100%;
				padding: 10px 15px !important;
				border-bottom: 1px solid #ddd;
				&:last-child {
					border-bottom: none;
				}
			}
		}
		div.selector {
			max-width: 100%;
			width: 100%;
		}	
		/*CSS for cateogry tree*/
		&#categories_block_left {
			.title_block {
				margin-top: 0;
			}
			/*Toggle icon*/
			span.grower {
				background: none;
				font-size: 18px;
				top: 0px;
				width: 30px;
				height: 42px;
				line-height: 38px;
				color: $colorText_3;
				text-align: center;
				z-index: 2;
				@media (max-width: 991px) and (min-width: 768px) {
					height: 37px;
					line-height: 35px;
				}
				@media (max-width: 767px) {
					padding: 0 15px;
					width: 40px;
					height: 40px;
					right: -1px;
				}
				&:before {
					content:"\f13a";	
					font-size: em(12px);
					display: inline-block;	
					color: inherit;
					width: auto;
					height: auto;
					text-align: right;
					line-height: 1;
					-webkit-transition: all .3s;
					-o-transition: all .3s;
					transition: all .3s;
					-webkit-transform-origin: 50% 50%;
					-moz-transform-origin: 50% 50%;
					-ms-transform-origin: 50% 50%;
					-o-transform-origin: 50% 50%;
					transform-origin: 50% 50%;
					@media (max-width: 767px) {
						font-size: 13px;
					}		
				}
				&.OPEN {
					&:before {
						content: "\f139";
					}
				}
			}
			.tree {
				margin-bottom: 0;
				>li {
					border-bottom: 1px solid #ddd;
					text-transform: uppercase;
					font-size: em(14px);
					&:last-child {
						margin-bottom: -1px;
					}					
					> a {
						font-weight: bold;
						padding: 10px 15px;
						&:before {
							content: "\f054";
							font-family: $fontIcon;
							font-size: em(12px);
							font-weight: normal;
						color: $colorText_3;
							padding-right: 6px;
						}
					}
					> ul {
						padding-top: 10px;
						padding-bottom: 10px;
					}
					> .grower {
						&.OPEN {
							color: $colorText_2;
							+a {
								color: $colorText_2;
								background: $mainColor_1;
								&:before {
									color: $colorText_2;
								}	
							}
						}
					}
					&:hover, &.selected {
						> a {
							color: $colorText_2;
							background: $mainColor_1;
							&:before {
								color: $colorText_2;
							}
						}
						> .grower {
							color: $colorText_2;	
						}
					}
				}
			}
			ul {
				border: none;
			}
			li {
				a {
					padding: 5px 15px;
				color: $colorText_4;
					font-weight: normal;
					border-color: #eee;
					line-height: 1.5;
					border: none;
					position: relative;
					z-index: 1;
					&:hover {
						background: none;
					}
					&.selected {
					color: $colorText_3;
						text-decoration: underline;
						background: none;
					}
				}
				
				ul {
					padding-left: 15px;
					.grower {
						width: 30px;
						height: 30px;
						line-height: 28px;
						&:hover + a {
							background:none;
						}
						@media (max-width: 767px) {
							padding: 0 15px;
							width: 40px;
						}	
					}
					li {
						text-transform: capitalize;
						a {
							&:before {
								line-height: 1;
								content: "\f054";
								font-size: em(10px);
								padding-right: 3px;
								float: left;
								margin-top: 6px;
							color: $colorText_3;
							}
						}
					}
				}
				
			}	
		}

		&#layered_block_left {
			.title_block {
				display: none;
			}
			.block_content {
				border: none;
			}
			#enabled_filters {
				padding: 0;
				border: none;
				background: none;

				ul {
					padding-top: 0;
					padding-right: 0px;
					li {
					color: $colorText_4;
					}
				}
			}	
		}

		&#manufacturers_block_left,
		&#suppliers_block_left,
		&#blog_categories_displayLeftColumn,
		&.myaccount-column {
			ul {
				border: none;
				padding: 0px;
				margin-bottom: 0;
			}
			.form-group {
				border: none;
				padding: 10px 15px 10px;
			}
			li {
				border: none;
				padding: 0;
				width: 100%;
				margin: 0;
				border-bottom: 1px solid #ddd;
				&:last-child {
					margin-bottom: -1px;
				}
				a {
					padding: 10px 15px;
					display: block;
					text-transform: uppercase;
				color: $colorText_4;
					font-weight: bold; 
					border-color: #eee;
					line-height: 1.5;
					border: none;
					position: relative;
					z-index: 1;
					font-size: em(14px);
					&:hover, &.selected {
						background: none;
					color: $colorText_3;
					}
					&:before {
						line-height: 1;
						content: "\f0da";
						font-family: $fontIcon;
						color: inherit;	
						padding-right: 8px;
					}
				}
			}
			.logout {
				text-align: right;
				padding: 10px 15px;
				@include clearfix();
				a {
					float: left;
					margin-top: 0;
				}
			}
		}

		&#blog_lastest_posts_displayLeftColumn {
			ul {
				margin: 0;
				li {
					margin: 0;
					padding: 10px 15px;
					border-bottom: 1px solid #ddd;
					&:last-child{
						border-bottom: none;
					}
					h3 {
						font-size: em(14px);
						text-transform: uppercase;	
					color: $colorText_3;
						line-height: 1.2;
						font-weight: normal;
						margin-top: 0;
						margin-bottom: 5px;
						a {
							color: inherit;
						}
					}
					.post-description {
						font-family: $fontText;
					}
				}
			}	
		}

		&#viewed-products_block_left {
			ul {
				margin-bottom: 0;
				li {
					margin-bottom: 0px;
					padding: 10px 15px;
					border-bottom: 1px solid #ddd;
					@media (max-width: 991px) {
						padding: 10px 5px;
					}
					h5 {
						margin: 10px 0 7px 0;
					}
					.product-name {
						@include fontStyle--5();
						text-transform: none;
					}
					.products-block-image {
						width: 64px;
						margin-right: 15px;
						@media (max-width: 991px) {
							float: left;
							margin-right: 5px;
						}
					}
					&:last-child {
						border: none;
					}
				}
			}	
		}

		&#blog_comments_displayLeftColumn {
			ul {
				margin: 0;
				li {
					margin: 0;
					padding: 10px 15px;
					border-bottom: 1px solid #ddd;
					&:last-child {
						border-bottom: none;
					}
					h3 {
						margin-top: 0;
						@include fontStyle--6();
						text-transform: uppercase;	
					color: $colorText_3;
						line-height: 1.2;
						margin-bottom: 5px;
						a {
							color: inherit;
						}
					}
					.comment-content {
						padding: 0;
						margin-bottom: 10px;
						font-family: $fontText;
					}
				}
			}	
		}	
		
		&#best-sellers_block_right {
			.products-block {
				margin-bottom: 0;
				li {
					padding: 10px 15px;
					margin-bottom: 0;
					border-bottom: 1px solid #ddd;
					@media (max-width: 991px) {
						padding: 10px 5px;
					}
					.products-block-image {
						width: 64px;
						margin-right: 15px;
						@media (max-width: 991px) {
							margin-right: 5px;
							float: left;
						}
					}
					.product-content {
						h5 {
							margin: 10px 0 7px;
						}
						.product-name {
							text-transform: none;
							@include fontStyle--5();
						}	
						.price-box {
							.price {
								font-size: em(18px);
							color: $colorText_3;		
							}
						}
					}
				}
			}
			.lnk {
				@include clearfix();
				padding: 10px 15px;
				line-height: 0;
				text-align: left;
				a {
					float: none;
				}
			}	
		}

		&#blog_tags_displayLeftColumn {
			.block_content {
				padding: 10px 15px;
				a {
					margin-bottom: 0;
				}
			}	
		}
	}	
}

/* CATEGORY CENTER BLOCK*/
.category #center_column {
	
	.content_scene_cat {
		border: none;
		padding-top: 9px;
	}

	.content_scene_cat_bg {
		padding: 0;
		background-color: transparent!important;

		img {
			width: 100%;
			margin-bottom: 20px;
		}

		.category-name {
			float: left;
			width: 100%;
			@extend .page-subheading;
		color: $colorText_4;
			text-transform: uppercase;
			line-height: 1;
			margin-bottom: 15px;
		}

		.cat_desc {
			color: $colorText;
			.rte {
				strong {
					@include fontStyle--6();
				color: $colorText_4;
					font-weight: bold;
				}
			}
		}
	}

	.page-heading {
		display: none;
	}

	#subcategories {
		border: none;
		position: relative;
		margin-bottom: 60px;
		@media (max-width: 767px) {
			margin-bottom: 30px;
		}

		.owl-controls {
			.owl-prev,
			.owl-next {
				background: $mainColor_2;
				position: absolute;
				top: 50%;
				margin-top: -20px !important;
				width: 40px;
				height: 40px;
				&:before {
					line-height: 40px;
					font-size: 14px;
				}
				&:hover {
					color: $colorText_2 !important;
					background: $mainColor_1;
				}
			}
			.owl-prev {
				left: 0;
			}
			.owl-next {
				right: 0;
			}
		}

		.subcategory-name {			
			@include fontStyle--3();
			color: $colorText_2;
			font-weight: bold;
		}

		.subcategory-heading {
			text-transform: uppercase; 
			@extend .page-subheading;
		}

		ul {
			margin: 0 -15px 0 -15px;
			width: auto;
			display: block;
			position: relative;
			z-index: 0;
			@include show-navigation();
			li {
				margin: 0;
				height: auto;
				padding: 0px 15px;
				width: 100%;

				h5 {
					position: absolute;
					width: 100%;
					left: 0;
					top: 50%;	
				color: $colorText_4;
					font-weight: bold;
					transform: translate(0,-50%) scale(1.5,1.5);
					-webkit-transition: all .6s;
					-o-transition: all .6s;
					transition: all .6s;
					z-index: 3;
					margin-top: 0px;
					opacity: 0;

					a {
						font-weight: bold;
						color: inherit;
					}
				}

				&:hover {

					.subcategory-image {
						a:before, a:after {
							background: rgba(67,69,113,0.1);
						}
						a:before {
							transform: scale(1,1);
						}
						a:after {
							transform: scale(1,1);
						}
					}
					h5 {
						transform: translate(0,-50%) scale(1,1);
						opacity: 1;
					}
				}
			}
		}

		.subcategory-image {
			padding: 0;
			position: relative;
			overflow: hidden;

			a {
				border: none;
				padding: 0;
				position: relative;
				&:before, &:after {
					content: "";
					display: block;
					width: 100%;
					height: 100%;
					left: 0;
					top: 0;
					position: absolute;
					transition: all 0.6s ease-in-out;
				}
				&:before {
					z-index: 1;	
					transform: scale(1,0);
					background: rgba(255,255,255,0.5);
				}

				&:after {
					z-index: 2;
					transform: scale(0,1);
					background: rgba(255,255,255,0.5);
				}
			}

			img {
				width: 100%;
				height: auto;		
				transition: all 0.35s;		
			}
		}
	}
}

/* LAYER SLIDER */
.layered_slider_container {
	width: 89%;
	position: relative;
	@media (max-width: 1199px) {
		left: -3px;
	}
	@media (max-width: 991px) {
		left: -8px;
	}
	
	.layered_slider {
		background: #eee;
		border: none;
		border-top: 1px solid #e0e0e0;	
		border-left: 1px solid #e0e0e0;	
		border-right: 1px solid #e0e0e0;	
		border-radius: 20px;	
	}

	.ui-widget-header {
		background: none;
		background-color: $mainColor_1;
		margin-top: -1px;
	}	

	.ui-slider-handle {
		width: 24px;
		height: 24px;
		border: none;
		background: none;
		background: url('../images/thumb-slider.png')no-repeat center;
		top: 50%;
		margin-top: -12px;
	}	
}

/* COLOR OPTION */
#layered_form {
	
	.color-option {
		border-color: #f3f3f3;
		width: 16px;
		height: 16px;
		position: relative;
	}

	div div ul {
		max-height: none;
	}
}

/* CONTENT SORT FILTER BAR */
.content_sortPagiBar {
	margin-bottom: 40px;
	@media (max-width: 991px) {
		margin-bottom: 20px;
	}
	@media (max-width: 767px) {
		margin-bottom: 10px;
	}

	div.selector {
		display: inline-block;
		width: 200px !important;
		@media (max-width: 992px) and (min-width: 768px) {
			width: 150px !important;
		}
	}

	#productsSortForm, .nbrItemPage {
		margin: 0 0 0 20px !important;
		display: inline-block;
		float: right !important;
		@media (max-width: 767px) {
			width: 50%;
			float: left !important;
			margin-left: 0 !important;
			.selector {
				width: 100% !important;
				select {
					width: 100%;
					max-width: 100% !important;
				}
			}
		}
	}
	#productsSortForm {
		@media (max-width: 767px) {
			padding-right: 15px;
			clear: both;
		}
	}
	.nbrItemPage {
		@media (max-width: 767px) {
			padding-left: 15px;
			.clearfix {
				>span {
					padding-left: 0!important;
				}
			}
		}
		.clearfix {
			>span {
				padding-left: 12px;
				display: none !important;
			}
		}
	}


	.sortPagiBar {
		border: 1px solid #d0d0d0;
		border-width: 1px 0;
		padding: 8px 0;
	}

	
	
	label {
	color: $colorText_4;
		padding-top: 8px;
		padding-right: 10px;
		@include fontStyle--4();
		@media (max-width: 767px) {
			width: 100%;
			text-align: left;
		}
	}

	.icon-grid-list {
		float: left;
		ul {
			margin: 0;
			@media (max-width: 767px) {
				display: block !important;
				float: none;
			}
		}

		.display-title {
			margin-top: 5px;
			font-family: $fontHeading;
			font-weight: normal !important;
			display: none;
			@media (max-width: 767px) {
				width: 100%;
				text-align: left;
				margin-bottom: 5px;
			}
		}

		li {
			a {
				font-family: $fontHeading;
				font-size: 0;
			color: $colorText_4;
				float: left;

				i {
					display: inline-block;
					font-size: 24px;
				color: $colorText_4;
					margin-right: 0px;
					position: relative;
					top: 3px;
				}
			}
		}

		#grid, #list { 
			height: auto !important;
			transform: translate3D(0,0,0);
			padding: 0;
			border: none;
			border-radius: 0px;
			background: none;
			cursor: pointer;
			padding-top: 4px;
			@media (max-width: 767px) {
				padding-top: 0;
			}

			&:hover, &.selected{
				background: none;

				a {
				color: $colorText_3;
					i {
					color: $colorText_3;
					}
				} 
			}
		}

		#grid {
			margin-right: 10px;
			i:before {
				content: "\f00a";
			}
		}
	}	

	.top-pagination-content {
		margin-top: 0px;
		padding-top: 20px;
		z-index: 0;
		@media (max-width: 991px) {
			padding-top: 10px;
		}
		.product-count {
			@include fontStyle--5();	
			color: $colorText_3;
		}
	}
}

.search {
	.content_sortPagiBar {
		label {
			
		}
		.compare-form {
		}
		#grid, #list {
			padding-top: 0;
		}
		.product-count {
			@include fontStyle--5();
			color: $colorText_3;
			display: inline-block;
			float: right;
			padding-top: 5px;
			@media (max-width: 991px) {

			}
		}
		#productsSortForm {
			float: left !important;
			margin-left: 0 !important;
			margin-top: -3px !important;
			@media (max-width: 767px) {
				width: 50%;
				clear: none;
				label {
					float: left;
					text-align: left;
					width: auto;
				}
			}
		}
	}
}
.category {
	.content_sortPagiBar {
		.icon-grid-list {
			
		}
	}
}

/* BOTTOM PAGING BLOCK*/
.bottom-pagination-content {
	border: none;
	padding-top: 0;

	.showall {
		display: none!important;
	}

	.product-count {
		float: right;
		color: $colorText_3;
		@include fontStyle--5();
		@media (max-width: 991px) {
			clear: none;
		}
		@media (max-width: 767px) {
			width: 100%;
		}
	}

	div.pagination {
		float: left;
		width: auto;

		ul {
			width: 100%;
			text-align: center;
			position: relative;
			font-size: 0;
			margin-top: 0;

			li {
				float: none;
				-webkit-transition: all .3s;
				-o-transition: all .3s;
				transition: all .3s;
				margin-right: 1px !important;
				>a,>span {
					margin: 0;
				}

				&:not(.pagination_nav){
					width: 34px;
					height: 34px;
					border: none;	
					

					a,span {
						width: 100%;
						height: 100%;
						background: $mainColor_2;
						border: none;
						font-size: em(14px);
						color: $colorText_2;
						line-height: 32px;
						-webkit-transition: all .3s;
						-o-transition: all .3s;
						transition: all .3s;
					}

					&.active,&.current, &:hover {

						a,span {
							background: $mainColor_1;
							border-color: $mainColor_1;
							color: $colorText_2;
						}
					}
				}

				&.pagination_previous, &.pagination_next,
				&#pagination_previous, &#pagination_next  {
					min-width: 34px;
					height: 34px;
					top: 0;
					vertical-align: top;

					&.disabled {
						opacity: 0.6;
					}

					b {
						display: none;
					}

					a, span {
						float: none;
						width: 100%;
						height: 100%;
						color: $colorText_2;
						position: relative;
						padding: 0;
						line-height: 38px;

						i {
							width: 34px;
							height: 34px;
							background: $mainColor_2;	
							text-align: center;
							font-size: 14px;
							line-height: 32px;
							-webkit-transition: all .3s;
							-o-transition: all .3s;
							transition: all .3s;
						}
					}

					&:not(.disabled):hover {
						border-color: $mainColor_1;

						a, span {
						color: $colorText_3;
						}

						i {
							background: $mainColor_1;
							color: $colorText_2;
						}
					}
				}

				&.pagination_previous, &#pagination_previous {
					left: 0;

					a, span {
						text-align: right;

						i {
							float: left;

							&:before {
								content: "\f060" !important;	
							}
						}
					}
					
				}

				&.pagination_next, &#pagination_next {
					right: 0;
					margin-left: 0;
					
					a, span {
						text-align: left;

						i {
							float: right; 

							&:before {
								content: "\f061" !important;
							}
						}
					}
					
				}
			}
		}
	} 
}

/* PRODUCT LAYOUT */
.columns-container ul.product_list {
	margin-left: -15px;
	margin-right: -15px;
	position: relative;
	@include clearfix();

	>p {
		@extend .ls-loading-container;
		font-size: 0;
		position: fixed;
		left: 50%;
		top: 50%;
		z-index: 999;
		background: black;
		img{
			display: none;
		}
	}

	.color-list-container {
		margin-bottom: 10px; 
		/*temporary hide*/
		display: none;

		ul {

			li {
				width: 20px;
				height: 20px;	

				a {
					width: 100%;
					height: 100%;
					margin: 0;
					position: relative;

					&:after {
						content: "";
						width: 14px;
						height: 14px;
						border: 4px solid #fff; 
						position: absolute;
						left: 2px;
						top: 2px;
						display: block;  
					}
				}
			}
		}
	}

	.availability span {
		background-color: transparent;
		border: none;	
		color: #55c65e;
	}

	&.grid {
		>li {
			padding: 0 15px;
			margin-bottom: 30px;
			clear: none;
			.product-container {
				padding: 15px 15px 10px;
			}
			@media (min-width: 992px) {
				&:nth-child(3n+1) {
					clear: both;
				}
			}
			@media (max-width: 991px) and (min-width: 480px) {
				&:nth-child(2n+1) {
					clear: both;
				}	
			}
		}		
	}

	&.list {
		> li {
			width: 100%;
			margin-bottom: 20px;
			@include clearfix();

			.product-container {
				float: left;
				width: 100%;

				.left-block {
					width: 30%;
					float: left;
					border: none;

					@media (max-width: 991px) {
						width: 45%;
					}
					@media (max-width: 767px) {
						width: 50%;

					}
					@media (max-width: 480px) {
						.functional-buttons {
							.ajax_add_to_cart_button,
							.addToWishlist, .add_to_compare,
							.quick-view {
								width: 30px;
								height: 30px;
								line-height: 30px;
							}
						}
					}
					.product-image-container {
						padding: 20px 0;
					}
				}

				.right-block {
					width: 70%;
					float: left;
					padding: 20px 0px 20px 15px;
					text-align: left;
					@media (max-width: 991px) {
						width: 55%;
					}
					@media (max-width: 767px) {
						width: 50%;
					}
					@media (max-width: 540px) {
						.functional-buttons {
							.ajax_add_to_cart_button
							{
								span {
									display: none;
								}
							}
						}
					}
					.product-name,
					.product-desc {
						padding-left: 0;
						padding-right: 0;
					}
					.product-desc {
						white-space: normal;
						line-height: 1.5;
					}
					.reviews-container {
						background: none;
						display: block; 
						width: 100%;
						.star_content {
							.star {
								&:after {
								color: $colorText_3!important; 
								}
							}
						}
					}

					.comments_note {
						text-align: left;
					}

					.content_price{
						background: none;
						width: 100%;
					}

					.lnk_view, 
					.product-flags {
						display: none;
					}
				}
			}
		}
	}
}
