/*================ Global | Sass Mixins ================*/
@mixin clearfix() {
  &:after, &:before {
    content: "";
    display: table;
    clear: both; }
  *zoom: 1;
}

@mixin prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}

@mixin transition($transition: 0.1s all) {
  @include prefix('transition', #{$transition});
}

@mixin transform($transform: 0.1s all) {
  @include prefix('transform', #{$transform});
}

@mixin animation($animation) {
  -webkit-animation: $animation;
  -moz-animation:    $animation;
  -o-animation:      $animation;
  animation:         $animation;
}

@mixin gradient($from, $to, $fallback) {
  background: $fallback;
  background: -moz-linear-gradient(top, $from 0%, $to 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$from), color-stop(100%,$to));
  background: -webkit-linear-gradient(top, $from 0%, $to 100%);
  background: -o-linear-gradient(top, $from 0%, $to 100%);
  background: -ms-linear-gradient(top, $from 0%, $to 100%);
  background: linear-gradient(top bottom, $from 0%, $to 100%);
}

@mixin backface($visibility: hidden) {
  @include prefix('backface-visibility', #{$visibility});
}

@mixin visuallyHidden {
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
}

@mixin desaturate {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

@mixin box-sizing($box-sizing: border-box) {
  -webkit-box-sizing: #{$box-sizing};
  -moz-box-sizing: #{$box-sizing};
  box-sizing: #{$box-sizing};
}

@function em($target, $context: $baseFontSize) {
  @if $target == 0 {
    @return 0;
  }
  @return $target / $context + 0rem;
}

@function color-control($color) {
  @if (lightness( $color ) > 50) {
    @return #000;
  }
  @else {
    @return #fff;
  }
}

@function menu-grid($totalWidth, $curCol, $totalCol) {
  @return $curCol * $totalWidth / $totalCol + 0px;
}

/*============================================================================
  Dependency-free breakpoint mixin
    - http://blog.grayghostvisuals.com/sass/sass-media-query-mixin/
==============================================================================*/
$min: min-width;
$max: max-width;
@mixin at-query ($constraint, $viewport1, $viewport2:null) {
  @if $constraint == $min {
    @media screen and ($min: $viewport1) {
      @content;
    }
  } @else if $constraint == $max {
    @media screen and ($max: $viewport1) {
      @content;
    }
  } @else {
    @media screen and ($min: $viewport1) and ($max: $viewport2) {
      @content;
    }
  }
}

/*============================================================================
  THEME MIXINS
==============================================================================*/
@mixin blog-hover($size: 15px) {
  overflow: hidden;
  position: relative;
  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(67, 69, 113, 0.1);
    transform: scale(1,1);
    transition: all 0.6s ease-in-out;
    z-index: 10;
  }  

  img{
    width: 100%;
    height: auto;
    -webkit-transition: all .35s;
    -o-transition: all .35s;
    transition: all .35s;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); 
  }

  &:hover {
    &:before {
      background: rgba(255, 255, 255, 0.5);
      transform: scale(0,1);  
    }
    &:after {
      background: rgba(255, 255, 255, 0.5);
      transform: scale(1,0);   
    }
    img {
      /*-webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);*/
    }
  }
}

@mixin bob-hover(){
  -webkit-animation-name: hvr-bob-float, hvr-bob;
  animation-name: hvr-bob-float, hvr-bob;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}

@mixin show-navigation() {
  .owl-controls {
    .owl-nav {
      .owl-prev, .owl-next {
        opacity: 0;
        @media(max-width: 991px) {
          opacity: 1;
        }
      }

      .owl-prev {
        transform: translate(100px, 0);
        @media(max-width: 991px) {
          transform: none;
        }
      }

      .owl-next {
        transform: translate(-100px, 0);
        @media(max-width: 991px) {
          transform: none;
        }
      } 
    }
  }
  &:hover {
    .owl-controls {
      .owl-nav {
        .owl-prev, .owl-next {
          opacity: 1;
          transform: translate(0,0);
        }
      }
    }  
  }
}

/*============================================================================
  Accent text
==============================================================================*/
@mixin fontStyle--1() {
  font-family: $fontHeading;
  font-weight: normal;
  font-size: em(36px);
}
@mixin fontStyle--2() {
  font-family: $fontHeading;
  font-weight: normal;
  font-size: em(30px);
}
@mixin fontStyle--3() {
  font-family: $fontHeading;
  font-weight: normal;
  font-size: em(24px);
}
@mixin fontStyle--4() {
  font-family: $fontHeading;
  font-weight: normal;
  font-size: em(18px);
}
@mixin fontStyle--5() {
  font-family: $fontHeading;
  font-weight: normal;
  font-size: em(16px);
}
@mixin fontStyle--6() {
  font-family: $fontHeading;
  font-weight: normal;
  font-size: em(14px);
}
