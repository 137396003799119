#index {
	/*Title block on Home*/
	.title_block {
		h2 {
			text-align: center;
			position: relative;
			padding-bottom: 20px;
			margin-bottom: 60px;
			overflow: visible;
			&:before {
				content: "";
				height: 1px;
				display: none;
				position: absolute;
				left: 50%;
				bottom: 0;
				background-color: #f3f3f3;
				width: 50vw;
				transform: translate(-100%, 0);
			}
			&:after {
				content: "";
				display: none;
				background-color: #fff;
				width: 10px;
				height: 10px;
				border: 2px solid #f3f3f3;
				border-radius: 10px;
				position: absolute;
				bottom: -5px;
				left: 50%;
				margin-left: -5px;
			}
		}
	}
	
	/*Home Banner*/
	.bc-banner-top  {
		padding-left: 0px;
		padding-right: 0px;
		padding-bottom: 60px;
		@media (max-width: 767px) {
			padding-bottom: 30px;
		}
		.bc-banner {
			@media (max-width: 767px) {
				margin-bottom: 20px;	
			}
			.bc-banner--wrapper {
				@include clearfix();
				overflow: hidden;
				position: relative;
				font-weight: bold;
				color: $colorText_4;
				.simple-text {
					@include fontStyle--4();
					font-family: $fontHeading_2;
					color: $colorText_4;
					position: absolute;
					transition: all 0.5s ease-in-out;
				}
				.color-text {
					@include fontStyle--3();
					color: $colorText_3;
					text-transform: uppercase;
					position: absolute;
					transition: all 0.5s ease-in-out;
				}
				.check-icon {
					width: 150px;
					height: 150px;
					position: absolute;
					top: 100%;
					right: -75px;
					display: block;
					background: $mainColor_1;
					transform-origin: 0% 0%;
					transform: rotate(-45deg);
					transition: all 0.3s ease-in-out;
					font-size: 30px;
					line-height: 1;
					color: $colorText_2;
					&:before {
						content: "\f00c";
						font-family: $fontIcon;
						display: inline-block;
						transform: rotate(45deg);
						position: absolute;
						left: 40px;
						top: 5px;
					}
					@media (max-width: 991px) {
						width: 100px;
						height: 100px;
						position: absolute;
						top: 100%;
						right: -50px;
						font-size: 15px;	
						&:before {
							left: 25px;
							top: 5px;
						}
					}
				}
				
			}
			&:nth-child(2) {
				.bc-banner--wrapper	{
					height: 0;
					padding-bottom: 62.82527%;
					.simple-text {
						top: 10%;
						right: 5%;
						opacity: 0;
						color: $colorText_2
					}
					
	
					&:hover {
						.simple-text {
							opacity: 1;
						}
					}
				}
			}
			&:nth-child(1) {
				.bc-banner--wrapper	{
					height: 0;
					padding-bottom: 29.82175%;
					.simple-text {
						top: 20%;
						left: 5%;
						margin-left: 30px;
						opacity: 0;
						color: $colorText_2;
					}
					.color-text {
						left: 5%;
						top: 40%;
						transform: scale(1.5, 1.5);
						opacity: 0;
					}
					img {
						width: 120%;
						height: auto;
						max-width: 200%;
						margin-left: -10%;
						transition: all 0.3s ease-out;
					}
					&:hover {
						.simple-text {
							opacity: 1;
							margin-left: 0;
						}
						.color-text {
							transform: scale(1, 1);
							opacity: 1;
						}
						img{
							animation-name: bannerParallax;
							animation-duration: 30s;
							animation-fill-mode: both;
							animation-iteration-count: infinite;
							animation-timing-function: linear;
						}
					}
				}
			}
			.bc-banner-left, .bc-banner-right {
				float: left;
			}
			.bc-banner-left {
				width: 60%;
				padding: 48px 0 40px 55px;
				@media (max-width: 767px) {
					width: 100%;
					text-align: center;
					padding: 20px;
				}
			}
			.bc-banner-right {
				width: 40%;
				text-align: center;
				padding-top: 48px;
				padding-bottom: 500px;
				margin-bottom: -500px;
				background: url('../images/shadow-line.png') no-repeat left 10px;
				@media (max-width: 1229px) {
					padding-left: 20px;
					padding-right: 20px; 
				}
				@media (max-width: 991px) {
					padding-top: 60px; 
				}
				@media (max-width: 767px) {
					width: 100%;
					text-align: center;
					padding-top: 0;
					padding-bottom: 20px;
					margin-bottom: 0;
					background: none;
				}
			}
		}
	}

	/*Home Tab Section*/
	.type-tab {
		.nav-tabs {
			background: $mainColor_2;
			border-bottom: none;	
			li {
				float: left;
				a {
					margin-right: 0;
					color: $colorText_2;
					border: none;
					@extend .h4;
					text-transform: uppercase;
					padding: 15px 20px;
				}
				&.active, &:hover {
					a {
						background: $mainColor_1;
					}
				}
			}
		}
		.tab-content {
			margin: 20px 0;
		}
		.owl-controls {
			position: absolute;
			right: 15px;
			top: -54px;
		}
		.ajax_add_to_cart_button {
			@media (max-width: 991px) and (min-width: 768px) {
				span {
					display: none;
				}	
			}
			@media (max-width: 570px) {
				span {
					display: none;
				}
			}
		}

	}
	.bc-productwithcategory {
		.title_block {
			display: none;
		}	
	}

	/*Home bottom section*/
	.bc-bottom-home {
		/*parallax section*/
		.bc-fashion-shop {
			position: relative;
			background: url('../images/parallax-img.jpg') no-repeat;
			background-position: center -250px;
			background-size: cover; 
			text-align: center;
			padding-top: 100px;
			padding-bottom: 90px;
			margin-bottom: 100px;
			@include fontStyle--5();
			color: #fff;
			line-height: 1.8;
			@media(max-width: 1229px) and (min-width: 768px) {
				background-position: center -150px;
			}
			@media(max-width: 767px) {
				background-position: center center !important;
				background-attachment: fixed;
			}
			&:before {
				content:'';
				display: block;
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				background-color: rgba(0,0,0,0.7);
			}
			&.on_mobile {
				background-attachment: fixed;
			}
			.bc-shop-text {
				position: relative;
				top: 100px;
				@media (max-width: 1229px) {
					top: 60px;
				}
				@media (max-width: 767px) {
					top: 0 !important;
				}
			}
			.icon {
				margin-bottom: 40px;
				@media (max-width: 767px) {
					margin-bottom: 20px;
				}
				&:before {
					content: "\f10d";
					display: block;
					font-family: $fontIcon;
					font-size: em(30px);
					color: #fff;
					width: 90px;
					height: 90px;
					border-radius: 90px;
					text-align: center;
					line-height: 90px;
					border: 1px solid #fff;
					@media (max-width: 767px) {
						width: 60px;
						height: 60px;
						line-height: 60px;
						font-size: em(20px);
					}
				}
			}
			h3.title {
			color: $colorText_3;
				text-transform: uppercase;
				margin-bottom: 20px;
				line-height: 1.2;
				@media (max-width: 767px) {
					font-size: em(20px);
				}
			}
			.desc {
				margin-bottom: 35px;
			}
			.author {
				font-size: em(14px);	
				text-transform: uppercase;
				span {
					text-transform: none;
				}
			}
			@media (max-width: 1229px) {
				padding-top: 80px;
				padding-bottom: 80px; 
			}
			@media (max-width: 991px) {
				padding-top: 40px;
				padding-bottom: 40px; 
			}
		}
		/*latest blog*/
		.bc-lastest-post {
			@include clearfix();
			border-bottom: 1px solid #f3f3f3;
			padding-bottom: 40px;
			margin-bottom: 100px;
			.lastest-post-button {
				display: none; 
			}
			#bc_lastest_post_content {
				>div {
					padding: 0 15px;
					width: 50%;
					float: left;
					@media (max-width: 767px) {
						width: 100%;
					}
				}
				.bc-item {
					margin-bottom: 60px;
					.bc-bloglastest-img {
						width: 200px;
						float: left;
						margin-right: 30px;	
						position: relative;
						@include blog-hover(10px);
						@media (max-width: 991px) and (min-width: 767px) {
							width: 150px;	
						}
						@media(max-width: 480px) {
							width: 130px;
							margin-right: 10px;
						}
					}
					.bc-bloglastest-right-content {
						float: left;
						width: calc(100% - 230px);
						position: relative;
						@media (max-width: 991px) and (min-width: 767px) {
							width: calc(100% - 180px);
						}
						@media(max-width: 480px) {
							width: calc(100% - 140px);
						}
						h3 {
							font-size: em(14px);
							font-weight: bold;
						color: $colorText_3;
							text-transform: uppercase;
							margin-top: 0px;
							margin-bottom: 17px;
							a {
								color: inherit; 
							}
						}
						.frame-date-author-comment {
							margin-bottom: 5px;
							.comment-count {
								display: none;
							}
							.date_add {
								font-size: em(14px);
								font-style: italic;
								display: inline-block;
							}
							.author {
								display: inline-block;
								margin-left: 5px;
								&:before {
									content:"/";
									font-size: em(14px);
									display: inline-block;
									margin-right: 5px;
								}
							}
							span {
								font-size: em(14px);
								font-style: italic;
								&:nth-child(1) {
								color: $colorText_4;
									font-weight: bold;
									font-style: normal;
									margin-right: 5px;
									display: none;
								}
							}	
						}
					}
				}
			}	
		}
		/*manufactures*/
		.manufacturer-container {
			#manufacture_list {
				@include show-navigation();
				.owl-item {
					/*border-right: 1px solid #eee;
					&.last {
						border-right: none;
					}*/
				}
				.owl-controls {
					.owl-prev, .owl-next {
						position: absolute;
						top: 50%;
						margin-top: -20px !important;
					}
					.owl-prev {
						left: 0;
					}
					.owl-next {
						right: 0;
					}
				}
				.manufacture-item{
					padding: 0 15px;
					text-align: center;

					/*@media (max-width: 991px) and (min-width: 768px) {
						padding: 20px 30px;
					}
					@media (min-width: 768px) {
						&:nth-child(1),&:nth-child(2),
						&:nth-child(3),&:nth-child(4) {
							border-top-color: transparent;
						}
						&:nth-child(4n) {
							border-right-color: transparent;
						}
					}
					@media (max-width: 767px) {
						width: 50%;
						&:nth-child(1),&:nth-child(2) {
							border-top-color: transparent;
						}
						&:nth-child(2n) {
							border-right-color: transparent;
						}
					}
					@media (max-width: 480px) {
						padding: 20px 30px;	
					}*/
				}	
			}	
		}	
	}
}