/*=========================================== CSS FOR HEADER ===========================================================*/
.header-container {
	@media (max-width: 767px) {
		padding-bottom: 0px;
	}
}
header {

	.banner {
		background-color: $colorLimit;
	}

	.container {
		overflow: visible;
	}

	.nav {
		background-color: $mainColor_2;
		border-bottom: 1px solid #f3f3f3;

		nav {
			min-height: 44px;
			padding-left: 15px;
			padding-right: 15px;
			font-family: $fontHeading;
			font-size: em(14px);
			@media (max-width: 991px) {
				text-align: center;
			}
		}

		#languages-block-top, #currencies-block-top {
			float: right;
			border: none;
			height: 44px;
			font-size: em(14px);
			@media (max-width: 991px) {
				float: none;
				display: inline-block;
				vertical-align: top;
				border: none;
				height: 30px;
			}
			.current {
				color: $colorText_2;
				padding: 0 10px 0 10px;
				line-height: 44px;
				transition: all 0.3s ease-out;
				@media (max-width: 991px) {
					line-height: 30px;	
				}
				@media (max-width: 480px) {
					padding: 0 5px 0 5px;
				}
				&::after {
					content: "\f0d7";
					color: inherit;
					font-size: 14px;
					padding-left: 3px;
					vertical-align: 0px;
					text-shadow: none;
				}

				span, strong {
					color: inherit;	
					text-shadow: none;
					font-weight: normal;
				}

				&:hover, &.active {
				color: $colorText_3;
					background: none;
				}
			}

			ul {
				background: #fff;
				border: 1px solid #eee;
				border-top: none;
				top: 44px;
				left: -1px;
				@media (max-width: 991px) {
					top: 30px;
				}
				li{
					a, >span {
						color: $colorText;
						padding-left: 10px;
					}
					&.selected, &:hover {
						background: $mainColor_1;
						a {
							color: #fff;
							background: $mainColor_1;
						}
						>span {
							color: #fff;
						}
					}
				}
			}
		}

		.social-header {
			float: right;
			display: none;
			ul {

				li {
					float: left;
					height: 54px;	
					font-family: "FontAwesome";
					color: #333;
					cursor: pointer;
					border-right: 1px solid #eee;
					-webkit-transition: all .5s;
					-o-transition: all .5s;
					transition: all .5s;
					@media (max-width: 991px) {
						border: none;
						height: 30px;
					}

					a {
						font-size: 0;
						padding-top: 0;
						width: 47px;
						height: 54px;
						line-height: 54px;
						text-align: center;
						display: inline-block;
						color: $colorText;
						@media (max-width: 991px) {
							width: 30px;
							height: 30px;
							line-height: 30px;
						}
						&:hover {
						color: $colorText_3;
						}
						&:before {
							font-size: em(14px);
						}
					}

					&.facebook {

						a:before {
							content: "\f09a";					
						}
					}

					&.twitter {

						a:before {
							content: "\f099";							
						}
					}

					&.google-plus {

						a:before {
							content: "\f0d5";							
						}
					}

					&.dribbble {

						a:before {
							content: "\f17d";							
						}
					}

					&.linkedin {

						a:before {
							content: "\f0e1";							
						}
					}
				}
			}
		}

		.header_user_info {
			border: none;
			padding-left: 0px;
			padding-top: 14px;
			float: left;
			@media (max-width: 991px) {
				width: 100%;
				text-align: center;
				padding-top: 0px;
				margin-bottom: 10px;
				span, a {
					float: none !important;
					display: inline-block;
				}
			}
			@media (max-width: 767px) {
				@include clearfix();
				clear: both;
				float: none;
				padding: 0 15px;
				text-align: center;

				span, a {
					float: none!important;
					display: inline-block;
				}
			}

			span {
				float: left;
				padding-right: 5px;
			color: $colorText_3;   
			}

			a {
				padding: 0;
				color: $colorText;
				float: left;
				&:hover {
					background: none;
				color: $colorText_3;
				}
			}
		}

		.header_links {  
			@include clearfix();
			display: inline-block;
			float: left;
			text-align: center;
			@media (max-width: 991px) {
				width: 100%;
			}

			.dropdown-toggle {
				display: none;
			}

			#header_links {
				display: inline-block!important;
				position: relative;
				margin-top: 0;
				-webkit-box-shadow: none;
				box-shadow: none;
				border: none;
				background-color: transparent;
				padding-top: 10px;
				@media (max-width: 991px) {
					float: none;
				}

				li {
					border-left: none;
					color: $colorText_2; 					
					padding: 0 20px 0 0;
					@media (max-width: 991px) {
						padding: 0 10px 0 10px;
					}
					@media (max-width: 480px) {
						padding: 0 5px 0 5px;
					}					

					a {
						padding-left: 0;
						padding-right: 0;
						font-size: em(14px);
						color: $colorText_2;

						&:hover {
							background-color: transparent;
						color: $colorText_3;
							text-decoration: none;
						}
					}

					.icon {
						margin-right: 5px;
						font-size: em(10px);
					}
				}
			}

			@media (max-width: 767px) {
				float: none;
				margin: 0 auto;
				display: block;

				ul#header_links {
					float: none;
				}
			}	
		}
	}

	.header-middle {
		background: #fff;
		padding-top: 43px;	
		padding-bottom: 31px;
		border-bottom: 1px solid #f3f3f3;
		@media (max-width: 767px) {
			border: none;
			padding-top: 35px;
			padding-bottom: 0px;
		}
		#header_logo {
			@include clearfix();
			padding-top: 5px;
			img {
				margin: 0 auto;
			}
			@media (max-width: 767px) {
				clear: both;
				padding: 10px 15px 20px;
			}
			+ div {
				position: static;
				@media (max-width: 991px) {
					
				}
			}
		}

		.contact-info {
			@media (max-width: 991px) {
				text-align: center;
			}
			ul {
				@include clearfix();
				margin-bottom: 15px;
				li {
					float: left;
					margin-right: 20px;
					@include fontStyle--5();
				color: $colorText_4;
					@media (max-width: 991px) {
						float: none;
						display: inline-block;
					}
					i{
						font-size: em(18px);
					color: $colorText_3;
						margin-right: 9px;
					}
				}
			}	
		}

		#search_block_top {
			width: 100%;
			float: left;
			padding-top: 0;
			position: relative;
			z-index: 10;
			@media (max-width: 767px) {
				margin-bottom: 10px;
			}
			form {
				position: relative;
		
			}
			#search_query_top {
				width: 100%;
				height: 46px; 
				padding: 0 56px 0 15px;
				background-color: #fff; 
				border-radius: 0;
				border-color: $mainColor_1 !important;
				@include fontStyle--4();
			}

			.button-search {
				width: 46px;
				height: 46px;
				position: absolute;
				background: transparent;
				right: 0px;
				top: 0px;
				border-radius: 0;
				border: none;
				padding: 0;

				&:before {
					font-size: em(18px);
				color: $colorText_3;
				}
				&:hover {
					color: #fff;
				}
				@media (max-width: 480px) {
					width: 35px;
					height: 35px;
				}
			}

			#show_result {
				position: absolute;
				background: #fff;
				min-width: 270px;
				width: 100%;
				left: 0;
				top: calc(100% + 9px);
				z-index: 100;
			color: $colorText_4;
				.result-wrapper {
					@include clearfix();
					border: 1px solid $mainColor_1;
				}
				/*-webkit-box-shadow: 0 5px 10px 0 rgba(0,0,0,0.4);
				box-shadow: 0 5px 10px 0 rgba(0,0,0,0.4);*/
				
				.result {
					width: 100%;
					padding: 15px;
				}
				#closedform {
					@extend .fancybox-close;
					position: absolute;
					font-size: 0;
					right: 0px!important;
					top: 0px!important;
					cursor: pointer;
					&:hover {
						color: #ff3837;
					}
				}
				h1 {
					@include fontStyle--4();
					color: $colorText_3;
					font-weight: normal;
					margin: 0;
					padding: 0 0 20px;
				}
				#search-products_block {
					li {
						border-bottom: 1px solid #ddd;
						.products-block-image {
							float: left;
							margin-right: 10px;
							width: 64px;
							margin-right: 15px;
						}
						h5 {
							margin-top: 0;
							margin-bottom: 10px;
							.product-name {
								color: $colorText_3;
							}

						}
						.price-box{
							.price {
								@include fontStyle--5();
								color: $colorText_4;
							}
						}
						&:last-child {
							border: none;
							margin-bottom: 0;
							padding-bottom: 0;
						}
					}	
				}
			}
		}

		.shopping_cart {
			padding-top: 0;
			float: left;
			border: 1px solid $mainColor_1;
			padding: 12px 15px 11px;
			width: 100%;
			@media (max-width: 767px) {
				float: none !important;
				width: auto;
				margin-right: 56px;
			}

			> a:first-child {
				@include clearfix();
				background: transparent;
				text-shadow: none;
				color: $colorText;
				text-transform: uppercase;
				font-size: em(16px);
				padding: 0;
				overflow: hidden;
				font-weight: normal;
				padding-top: 3px;
				&:before {
					content: "\f07a";
					color: $colorText_3;
					padding-right: 0;
					margin-right: 5px;
					float: left;
					margin-top: -5px;
					
				}
				&:after {
					content: "\f0d7";
					line-height: 1;
					padding-top: 0 !important;
					margin-left: 5px;
					font-size: 14px;
					color: $colorText_3 !important;
				}
				/*@media (max-width: 767px) {
					padding: 0;
					.ajax_cart_quantity {
						display: block !important;
						width: 20px;
						height: 20px;
						position: absolute;
						right: -5px;
						border-radius: 50%;
						top: -5px;
						background: #373737;
						text-align: center;
						padding-right: 0 !important;
						color: #fff;
						line-height: 20px;
					}
				}*/

				b {
				color: $colorText_4;
					text-shadow: none;
					&:after {
						content: ":";
						display: inline-block;
					}
					@media (max-width: 1229px) {
						display: none !important;
					}
					@media (max-width: 767px) {
						display: block !important;
					}
					@media (max-width: 480px) {
						display: none !important;
					}
				}

				b, >span {
					float: left;
					@include fontStyle--5(); 
					padding-left: 5px;
					display: inline-block;
					&.unvisible {
						display: none;
					}
				}

				.ajax_cart_product_txt,
				.ajax_cart_product_txt_s {
					//display: none !important;
				color: $colorText_3;
					padding-left: 3px;
				}
				
				.ajax_cart_quantity {
					text-align: center;
					color: $colorText_3;
					@media (max-width: 991px) and (min-width: 768px) {
						position: absolute;
						top: 1px;
						left: 10px;
						background: $mainColor_2;
						text-align: center;
						border-radius: 50%;
						width: 18px;
						height: 18px;
						color: $colorText_2;
						padding: 0;
					}
				}

				.ajax_cart_total,
				.ajax_cart_no_product {
					font-weight: normal;
				color: $colorText_3;
					display: inline-block;
					padding-top: 0px;
					white-space: nowrap;
				}

				.ajax_cart_total {
					padding-left: 3px;
					&:before {
						content: "-";
						display: inline-block;
						padding-right: 3px;
					}
				}

				&:hover {
					.ajax_cart_quantity {
						//@include bob-hover();
					}
					&:after {
						padding: 0 !important;
					}	
				}
			}
		}
	}

	/*cart block*/
	&#header {
		.cart_block {
			top: calc(100% + 10px);
			left: 0;
			margin-left: -1px;
			min-width: 270px;
			background: #fff;
			border: 1px solid $mainColor_1;
			color: #373737;
			/*-webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.4);
			box-shadow: 0 5px 10px rgba(0,0,0,0.4);*/
			@media (max-width: 1229px) {
				left: auto;
				right: -1px;
			}
			@media (max-width: 767px) {
				left: 0;
				right: auto; 
				width: calc(100% + 57px);
			}

			.products {
				padding: 20px 20px 0;
			}
			.cart_block_list {
				.remove_link {
					right: 0;
					top: 0;
					.ajax_cart_block_remove_link{
						color: #373737;
						width: 20px;
						height: 20px;
						text-align: right;
						&:hover{
							color: red;
						}
						&:before{
							content:"\f146";
							font-size: 13px;
						}
					}	
				}
			}
			dt{
				-webkit-box-shadow: none;
				box-shadow: none;
				background: none;
				padding: 0;
				border-bottom: 1px solid #ddd;
				padding-bottom: 20px;
				margin-bottom: 20px;
				&.last_item {
					border: none;
				}
			}
			.cart-images {
				width: 64px;
				margin-right: 15px;
			}
			.cart-prices {
				background: none;
			}
			.cart-buttons {
				background: none;
			}
			.cart-info {
				.product-name {
					.cart_block_product_name {
					color: $colorText_3;
						&:hover {
						color: $colorText_4;
							text-decoration: underline;
						}
					}
					.quantity-formated {
					color: $colorText_4;
						.quantity{
							font-size: em(14px);
						}
					}	
				}
			}
			.product-atributes {
				a {
					font-size: em(14px);
					&:hover {
					color: $colorText_3;
					}
				}	
			}
			.cart-prices {
				span {
					font-weight: normal;
					&:not(.price) {
						@include fontStyle--5();
					}
				}
				.cart-prices-line {
					border-color: #ddd;
				}	
			}
			.price {
			color: $colorText_4;
				@include fontStyle--4();
				&.cart_block_total {
				color: $colorText_3;
				}
			}
			.cart-buttons {
				padding-top: 0;
				#button_order_cart {
					@include btn-style-3();
					i {
						margin-left: 10px;
					}
					span {
						border: none;
						background: none;
						padding: 0;
					}
				}	
			}
		}
		.banner {
			a {
				max-width: 100%;
				img {
					max-width: 100%;
				}
			}
		}
	} 
}