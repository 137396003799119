@mixin btn-style-1(){
	height: 38px;
	line-height: 38px;
	display: inline-block;
background-color: $colorBtn_1;
	text-transform: uppercase;
	@include fontStyle--5();
	color: #fff !important;
	text-align: center;	
	padding: 0 15px;
	border-radius: 0;
	&:hover {
	background-color: $colorBtn_2;
	}
}

@mixin btn-style-2(){
	height: 34px;
	line-height: 34px !important;
	padding: 0 15px;
	display: inline-block;
background-color: $colorBtn_1;
	text-transform: none;
	@include fontStyle--6();
	font-weight: none;
	color: $colorText_2 !important;
	text-align: center;	
	padding: 0 12px;
	border: none;
	border-radius: 4px;
	position: relative;
	z-index: 1;
	overflow: hidden;
	span {
		font-size: inherit !important;
		color: inherit !important;
	}
	&:after {
		/*content:'';
		display: block;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
		background-color: #fff;
		transform: scale(0,0);
		opacity: 0;	
		border-radius: 0px;*/
	}
	&:hover {
		color: $colorText_2 !important;  
	background-color: $colorBtn_2;
		/*&:after {
			transition: all .35s ease;
			transform: scale(1,1);	
			opacity: 1;
		}*/
	}
}

@mixin btn-style-3(){
	height: 34px;
	line-height: 34px !important;
	display: inline-block;
background-color: $colorBtn_2;
	text-transform: none;
	@include fontStyle--6();
	font-weight: normal;
	color: $colorText_2 !important;
	text-align: center;	
	padding: 0 15px;
	border: none;
	border-radius: 4px;
	position: relative;
	z-index: 1;
	overflow: hidden;
	span {
		font-size: inherit !important;
		color: inherit !important;
	}
	&:after {
		/*content:'';
		display: block;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	background-color: $colorBtn_1;
		transform: scale(0,0);
		opacity: 0;	
		border-radius: 0px;*/
	}
	&:hover {
		color: $colorText_2 !important;  
	background-color: $colorBtn_1; 
		/*&:after {
			transition: all .3s ease;
			transform: scale(1,1);	
			opacity: 1;
		}*/
	}
}

@mixin btn-cart() {
	span {
		transition: all 0.2s ease-out;
		@include btn-style-1();
	}
	&:before {
		transition: all 0.2s ease-out;
		content: '';
		display: inline-block;
		float: right;
		@include btn-functional-general();	
	}
	@include btn-functional-content("\f07a"); 
	&:hover {
		span {
		background-color: $colorBtn_2;
		}
		&:before {
			background: $colorBtn_1;
			content: "\f218";
		}
	}
}

@mixin btn-functional-general(){
	width: 38px;
	height: 38px;
	display: inline-block;
	font-size: 0;
	line-height: 38px;
	border-radius: 0px;
	border: none;
background-color: $colorBtn_2;
	color: $colorText_2;
	text-align: center;
	padding: 0;
	transform: translate3d(0,0,0);
	&:hover {
	background-color: $colorBtn_1;
		color: $colorText_2;
	}
}

@mixin btn-functional-content($content){
	&:before {
		content: $content;
		font-family: $fontIcon;
		display: block;
		font-size: em(14px);
		color: $colorText_2;
	}	
}