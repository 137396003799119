.module-bcblog-categoryPost {
	.select {
		text-align: right;
	}
	div.selector {
		max-width: 200px;
		display: inline-block;
		span {
			text-align: left;
		}
	}
	.content_sortPagiBar {
		.top-pagination-content {
			.nbrItemPage, #productsSortForm {
				margin-left: 0 !important;
				margin-right: 20px !important;
				float: left !important;
				@media (max-width: 767px) {
					width: 50%;
					margin-right: 0 !important;
					padding-left: 0;
					padding-right: 15px;
					clear: none;
					label {
						width: auto;
						float: left;
					}
					.selector {
						float: left;
					}
				}
			}
			.nbrItemPage {
				input {
					@include btn-style-3();
					border: none;
					vertical-align: bottom;
					@media (max-width: 767px) {
						float: left;
					}
				}
				
			}
			#productsSortForm {
				@media (max-width: 767px) {
					padding-right: 0;
					padding-left: 15px;
				}	
			}
		}	
	}
	.product-count{
		margin-top: 10px;
		display: inline-block;
		@include fontStyle--5();
	color: $colorText_3;
		margin-left: 10px;
	}
	.rss {
		margin-left: 10px;
		text-transform: uppercase;
		@include fontStyle--5();
	color: $colorText_3;
		a {
			color: inherit;
		}
		&:hover {
		color: $colorText_4;
			a {
				text-decoration: underline;
			}	
		}
	}
	.columns-container {
		/*Blog Category*/
		ul {
			&.product_list {
				margin-left: -15px;
				margin-right: -15px;
				border: none;
				.bc-item {
					margin-bottom: 40px;
					padding: 0 15px;
				}
				.bc-left-post {
					.post-image {
						position: relative;
						overflow: hidden;
						a {
							display: inline-block;
							@include blog-hover();
						}
					}
					
				}
				.bc-right-post {
					.post-name {
						margin-bottom: 0px;
						h3 {
							font-size: em(18px);
							font-weight: normal;
							text-transform: uppercase;
						color: $colorText_3;
							a {
								color: inherit;
								&:hover {
								color: $colorText_4;
									text-decoration: underline;
								}
							}
						}
					}
					.post-description {
						margin: 0;
						padding: 10px 0;
					}	
				}
				&.grid {
					.bc-item {
						float: left;
						width: 50%;
						&:nth-child(2n+1) {
							clear: both;
						}
					}
				}
				&.list {
					.bc-item {
						float: left;
						width: 100%;
						.bc-left-post {
							width: 50%;
							float: left;
							padding-right: 15px;
						}
						.bc-right-post {	
							width: 50%;
							float: left;
							h3 {
								margin-top: 0;
							}
						}
					}	
				}
			}
		}	
	}
}
.frame-date-author {
	font-family: $fontText;
	font-style: italic;
	
	color: $colorText;
	>div {
		display: block;
		@include clearfix();
	}
	span {
		font-family: $fontText;
		font-style: inherit;
		font-size: em(12px);
		color: inherit;
		display: inline-block;
		float: left;
		line-height: 1.5;
		&:nth-child(1) {
			@include fontStyle--6();
			font-style: normal;
		color: $colorText_4;
			padding-right: 5px;
			font-weight: bold;
		}
		&:nth-child(2) {
			padding-top: 2px;
		}
	}
}
.module-bcblog-post {
	.name_detail {
		text-transform: uppercase;
	color: $colorText_3;
		margin-bottom: 20px;
		margin-top: 0;
	}
	.img_detailblog {
		margin-bottom: 30px;
		text-align: center;
	}
	.plpost_content {
		padding-bottom: 10px;	
	}
	.tag_blog {
		padding-bottom: 10px;
		.title_tag_blog {
			@include fontStyle--5();
		color: $colorText_4;
		}
		.tag_list {
			font-style: italic;
			a {
				&:hover{
				color: $colorText_3;
					text-decoration: underline;
				}
			}
		}
	}

	.out_related_products {
		padding-top: 20px;
		padding-bottom: 20px;
		.title {
			@extend .page-subheading;		
		}
		.ajax_block_product {
			.product-image {
				border: none;
			}
			.product-name {
				@include fontStyle--4();
			color: $colorText_3;
				margin: 10px 0;
				a {
					color: inherit;
				}
			}
			.price {
				@extend .price.product-price;	
			}
			.ajax_add_to_cart_button {
				@include btn-cart();
				display: inline-block;
				margin-top: 10px;
			}
		}
		
	}
	
	/*Post detail Item*/
	#plpost{
		padding-top: 20px;
		padding-bottom: 20px;
	}
	/*Blog Comment Block*/
	.blog-comment-list {
		padding-top: 20px;
		padding-bottom: 20px;
		.title {
			@extend .page-subheading;	
		}
		.comment-item {
			margin-bottom: 20px;
			>div {
				span {
					font-family: $fontText;
					font-style: italic;
					&:nth-child(1) {
						@include fontStyle--6();
					color: $colorText_4;
						font-style: normal;
						padding-right: 5px;
						font-weight: bold;
						margin-right: 10px;
					}
				}
				&.comment-content {
					margin: 5px 0;
				}
			}
		}
	}
	/*Latest Article -- Same css from blog category -- */
	.related_posts {
		padding-top: 20px;
		padding-bottom: 20px;
		.title {
			@extend .page-subheading;	
		}
		.blog_content {
			.item-related-post {
				margin-bottom: 40px;
				&:last-child {
					margin-bottom: 0;
				}
				.post-name {
					margin-bottom: 0px;
					h3 {
						@include fontStyle--4();
						margin-top: 0;
					color: $colorText_3;
						text-transform: uppercase;
						a {
							color: inherit;	
						}					
						&:hover {
						color: $colorText_4;
							a {
								text-decoration: underline;
							}
						}	
					}
				}
				.post-description {
					margin: 0 0 10px;
					padding: 0;
				}
				.post-date-add, .post-author {
					@include clearfix();
					span {
						font-family: $fontText;
						font-style: inherit;
						font-size: inherit;
						color: inherit;
						display: inline-block;
						float: left;
						line-height: 1.5;
						&:nth-child(1) {
							@include fontStyle--6();
						color: $colorText_4;
							font-style: normal;
							padding-right: 5px;
							font-weight: bold;
						}
						&:nth-child(2) {
							padding-top: 2px;
							font-style: italic;
						}
					}	
				}	
			}	
		}	
	}	
	/*Blog Comment Form*/
	.pl_comment_form{
		padding-top: 20px;
		padding-bottom: 20px;
		.title {
			@extend .page-subheading;
		}
		.submit {
			a {
				@include btn-style-2();
			}	
		}
	}	
}
.post-name {
	a {
		&:hover {
		color: $colorText_3;
		}
	}
}